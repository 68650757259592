import * as React from "react";

const SVGCheckCircle = ({ fill, stroke, width, height }: any) => {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width || 24} height={height || 24} fill={fill || 'none'}>
		<g strokeWidth="0"/>
		<g strokeLinecap="round" strokeLinejoin="round"/>
		<g>
			<circle cx="12" cy="12" r="10" stroke={stroke || '#1C274C'} strokeWidth="1.5"/>
			<path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke={stroke || '#1C274C'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
	</svg>
  )
}
export default SVGCheckCircle;