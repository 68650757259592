import { sortByField } from "../../utils/format.utils";
import { TRANSACTIONS_ACTIONS } from "../actions/transactions.actions";
import { extractAPIErrorMessage } from "../services/error.service";

const createApiState = () => ({ pending: false, isError: false, errorCode: null, errorMessage: null });

const initialState = {
  transactionsData: {},
  transactionsFilters: {},
  transactionsPagination: { pageSize: 20, pageNumber: 1, scrollPosition: 0 },
  platformList: [],
  providerList: [],
  selectedTransaction: null,
  stateDetails: createApiState(),
  stateSearch: createApiState(),
  stateMetadata: createApiState(),
  isRegularizationError: false,
  isTransactionsSearchPending: false,
  isError: false,
  errorCode: null,
  errorMessage: null
}

export default function transactionsReducer(state = initialState, action: any) {
  switch (action.type) {
    //------------------
    // STATES
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_PENDING: {
      return {
        ...state,
        isError: false,
        isTransactionsSearchPending: true,
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_METADATA_PENDING: {
      return {
        ...state,
        stateMetadata: { isError: false, pending: true },
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_SUCCESS: {
      return {
        ...state,
        isTransactionsSearchPending: false,
        isError: false,
        transactionsData: action.payload
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_FAILED: {
      return {
        ...state,
        isTransactionsSearchPending: false,
        isError: true,
        errorCode: action.payload.data.status,
        errorMessage: extractAPIErrorMessage(action.payload.data),
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_LIST_FAILED: {
      return {
        ...state,
        transactionsData: {},
        isTransactionsSearchPending: false,
        isError: true,
        errorCode: action.payload.data.status,
        errorMessage: extractAPIErrorMessage(action.payload.data),
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_FILTERS: {
      return {
        ...state,
        transactionsFilters: action.payload,
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_PAGINATION: {
      return {
        ...state,
        transactionsPagination: { ...state.transactionsPagination, ...action.payload },
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_PLATFORMS: {
      return {
        ...state,
        stateMetadata: { isError: false, pending: false },
        platformList: sortByField(action.payload, 'name'),
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_PROVIDERS: {
      return {
        ...state,
        stateMetadata: { isError: false, pending: false },
        providerList: sortByField(action.payload, 'name'),
      }
    }
    // SEARCH TRANSACTION DETAILS
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_PENDING: {
      return {
        ...state,
        stateDetails: { pending: true, isError: false },
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_FAILED: {
      return {
        ...state,
        stateDetails: {
          pending: true,
          isError: true,
          errorCode: action.payload.data.status,
          errorMessage: extractAPIErrorMessage(action.payload.data),
        },
        selectedTransaction: null,
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_SUCCESS: {
      return {
        ...state,
        stateDetails: { pending: false, isError: false },
        selectedTransaction: action.payload,
        isRegularizationError: false,
      }
    }
    // TRANSACTION REGULARIZATION
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_REGUL_SUCCESS: {
      return {
        ...state,
        isRegularizationError: false,
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_REGUL_FAILED: {
      return {
        ...state,
        isRegularizationError: true,
      }
    }
    case TRANSACTIONS_ACTIONS.TRANSACTIONS_LIST_UPDATE: {
      return {
        ...state,
        transactionsData: action.payload
      }
    }
    default:
      return state;
  }
}