import React, { useState } from 'react';
import AlertToaster from './alertToaster.comp';

//====================================== Integration
/**
 * You can either directly use the <AlertToaster> component inside your page,
 * or progammatically show the shared top-right alert toaster: useToaster().showMessage(...)
 */

export const ToastContext = React.createContext<any>(null);

export const useToaster = () => {
  return React.useContext(ToastContext);
}

//====================================== Toast Provider

type ToastProviderProps = {
  children: JSX.Element|JSX.Element[]
}

type ShowMessageProps = {
  duration: number,
  message: string,
  severity: 'success' | 'info' | 'warning' | 'error',
  title: string,
  onClose?: () => void,
  showClose: boolean,
}

export const ToastProvider = ({
  children,
}: ToastProviderProps): JSX.Element => {

  const [isOpen, setOpen] = useState(false);

  const [_duration, setDuration] = useState<number>();
  const [_onClose, setOnClose] = useState<any>();
  const [_message, setMessage] = useState<string>();
  const [_title, setTitle] = useState<string>();
  const [_severity, setSeverity] = useState<ShowMessageProps["severity"]>();
  const [_showClose, setShowClose] = useState<boolean>(false);

  /**
   * Display a message.
   * @field {duration} default no duration
   * @field {message} text or JSX
   * @field {severity} 'success' | 'info' | 'warning' | 'error' - See Alert MUI
   * @field {title} 
   * @field {onClose} callback function (optional)
   * @field {showClose} show the close button (optional)
   * @returns undefined
   * @public
   */
  const showMessage = ({
    duration,
    message,
    severity,
    title,
    onClose,
    showClose,
  }: ShowMessageProps) => {
    setDuration(duration);
    setMessage(message);
    setSeverity(severity);
    setTitle(title);
    setOnClose(onClose);
    setShowClose(showClose);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    _onClose && _onClose();
  }

  const contextValue = {
    showMessage
  }

  return (
    <>
      <ToastContext.Provider value={contextValue}>
        {children}
      </ToastContext.Provider>

      <AlertToaster
        duration={_duration}
        onClose={handleClose}
        open={isOpen}
        message={_message}
        title={_title}
        severity={_severity}
        showClose={_showClose}
      />
    </>
  )
}

export default ToastProvider;
