export const errorMessageHandler = (errorCode: number) => {
  switch (errorCode) {
    case 301001:
      return 'Login.errorMessageLogin1';
    case 301002:
    case 301003:
    case 310000:
      return 'Login.errorMessageLogin2';
    default:
      return 'Login.errorMessageLogin3';
  }
}

export const extractAPIErrorMessage = (err: any) => {
  return err?.detail || err?.title || err?.error;
}