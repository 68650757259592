import * as React from "react";

const SVGProfil = (props: any) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12.1369 13.7065C12.1146 13.7065 12.0811 13.7065 12.0588 13.7065C12.0253 13.7065 11.9806 13.7065 11.9471 13.7065C9.41324 13.6283 7.51562 11.6526 7.51562 9.21916C7.51562 6.7411 9.53602 4.7207 12.0141 4.7207C14.4922 4.7207 16.5126 6.7411 16.5126 9.21916C16.5014 11.6638 14.5926 13.6283 12.1704 13.7065C12.1481 13.7065 12.1481 13.7065 12.1369 13.7065ZM12.003 6.3839C10.4402 6.3839 9.17883 7.65642 9.17883 9.208C9.17883 10.7373 10.3732 11.9763 11.8913 12.0321C11.9248 12.0209 12.0364 12.0209 12.1481 12.0321C13.6438 11.954 14.8159 10.7261 14.827 9.208C14.827 7.65642 13.5657 6.3839 12.003 6.3839Z" fill="#667085"/>
          <path d="M12.0046 24.0001C9.00194 24.0001 6.13319 22.8839 3.91187 20.8523C3.71095 20.6737 3.62165 20.4058 3.64397 20.1491C3.78909 18.8208 4.6151 17.5817 5.98808 16.6664C9.31448 14.4562 14.7059 14.4562 18.0212 16.6664C19.3941 17.5929 20.2202 18.8208 20.3653 20.1491C20.3988 20.417 20.2983 20.6737 20.0974 20.8523C17.8761 22.8839 15.0073 24.0001 12.0046 24.0001ZM5.39647 19.9258C7.24944 21.4774 9.58238 22.3258 12.0046 22.3258C14.4269 22.3258 16.7598 21.4774 18.6128 19.9258C18.4118 19.2449 17.8761 18.5863 17.0835 18.0505C14.3376 16.2199 9.68285 16.2199 6.91456 18.0505C6.12203 18.5863 5.5974 19.2449 5.39647 19.9258Z" fill="#667085"/>
          <path d="M11.9996 23.9992C5.38029 23.9992 0 18.6189 0 11.9996C0 5.38029 5.38029 0 11.9996 0C18.6189 0 23.9992 5.38029 23.9992 11.9996C23.9992 18.6189 18.6189 23.9992 11.9996 23.9992ZM11.9996 1.67436C6.30677 1.67436 1.67436 6.30677 1.67436 11.9996C1.67436 17.6924 6.30677 22.3248 11.9996 22.3248C17.6924 22.3248 22.3248 17.6924 22.3248 11.9996C22.3248 6.30677 17.6924 1.67436 11.9996 1.67436Z" fill="#667085"/>
      </svg>
    )
}
export default SVGProfil;