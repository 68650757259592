import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { Typography, useTheme } from '@mui/material';
import LOGO_OPERATOR from '../../../assets/images/LOGO_OPERATOR.png';
import { makeStyle } from './navbar.style';
import { useLocation, useNavigate } from "react-router-dom";
import { Location } from 'history';
import { ENV } from '../../../env';
import { tabSideBar } from '../../../pages/routes/routes';
import SVGCollapseLeft from '../../../assets/icons/SVGCollapseLeft';
import SVGArrowDownMenu from '../../../assets/icons/SVGArrowDownMenu';
import UserMgmtNavbar from '../userMgmtNavbar/userMgmtNavbar.comp';
import { RootState } from '../../../store/root.reducer';
import { IRouteModel } from '../../../models/core/routes.model';


function Navbar({ setActivePage }: any): JSX.Element {

  const styles = makeStyle(useTheme());
  const { t } = useTranslation();
  const location = useLocation() as any;
  const navigate = useNavigate();
  const [currentTab, selectTab] = useState(location?.state || tabSideBar[0]);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [arrowRotate, setArrowRotate] = useState(0);
  const userRights = useSelector((state: RootState) => state.login.userRights);

  useEffect(() => {
    recoverCurrentTab();
  }, [location])

  useEffect(() => {
    setArrowRotate(navbarOpen ? 0 : 180)
  }, [navbarOpen])

  const recoverCurrentTab = () => {
    //Note: Recover the current navigation based on the open (sub-)page.
    //      Used when we open a subpage directly from its URL.
    const newTabRoute = tabSideBar.find(route => {
      if (currentTab?.name != route.name && route.name != "explorer") {
        const reg = route.path.replace(/[:][^/]+/g, '[^/]+');
        return location?.pathname?.match(reg)?.length > 0;
      }
    });
    if (newTabRoute) {
      setActivePage(newTabRoute);
      selectTab(newTabRoute);
    }
  }

  const onItemListClick = (item: IRouteModel) => {
    setActivePage(item);
    selectTab(item);
    navigate(item.path);
  }

  const onItemCollapseClick = (tab: IRouteModel) => {
    setActivePage(tab);
    selectTab(tab);
    navigate(tab.path);
  }

  return (
    <>
      <Drawer
        sx={navbarOpen ? styles.drawerCollapse : styles.drawer}
        variant="permanent"
        open={navbarOpen}
      >
        <Box sx={styles.topBox}>
          <img style={styles.kpLogo} src={LOGO_OPERATOR} />
          {!navbarOpen && <p style={styles.kpLogoTitle}>{ENV.APP_NAME}</p>}
        </Box>
        <List>
          {tabSideBar.map((item, i) => (
            (!item.right || userRights.includes(item.right)) &&
            <div key={i}>
              <ListItem
                disablePadding
                onClick={() => onItemListClick(item)}
                style={{ flexDirection: 'column', alignItems: 'normal' }}
              >
                <ListItemButton
                  sx={[{
                    height: '40px',
                    borderRadius: '6px',
                    paddingLeft: '12px',
                    marginLeft: '16px',
                    marginRight: '12px',
                  },
                  currentTab?.name === item.name && !currentTab?.isMultipleRoutes ? { backgroundColor: '#F7F7F7' } : { "&:hover": { backgroundColor: "#FFF" } },
                  ]}
                  disableRipple
                >
                  {item.icon}
                  {!navbarOpen &&
                    <>
                      <p style={currentTab?.name === item.name ? styles.listItemTextSelected : styles.listItemText}>
                        {t(item.name)}
                      </p>
                      {item.isMultipleRoutes &&
                        <div style={{ marginLeft: 'auto', transform: `rotate(${currentTab?.name === item.name ? '180' : '0'}deg)` }}>
                          <SVGArrowDownMenu />
                        </div>}
                    </>
                  }
                </ListItemButton>
              </ListItem>
              <Collapse in={!navbarOpen && currentTab?.isMultipleRoutes && location.state.includes(item.name)} timeout="auto">
                <List>
                  {currentTab?.isMultipleRoutes && currentTab.routes?.map((tab: IRouteModel, index: number) => (
                    (tab.right == "" || userRights.includes(tab.right)) &&
                    <ListItemButton
                      key={index}
                      sx={
                        [{
                          padding: '8px 12px 8px 48px',
                          backgroundColor: '#F0F0F0',
                          borderRadius: '6px',
                          height: '40px',
                          marginLeft: '16px',
                          marginRight: '12px',
                          paddingLeft: '48px'
                        },
                        location.state === tab.name ? { backgroundColor: '#F7F7F7' } : { backgroundColor: "#FFF" },
                        ]}
                      disableRipple
                      onClick={() => onItemCollapseClick(tab)}
                    >
                      <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: 500 }}>
                        {t(tab.name)}
                      </Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>

        <Box sx={navbarOpen ? styles.bottomBoxCollapse : styles.bottomBox}>

          <Box
            sx={styles.collapseBox}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <div
              style={{
                width: '48px',
                height: '48px',
                cursor: 'pointer',
                transform: `rotate(${arrowRotate}deg)`,
              }}
            >
              <SVGCollapseLeft />
            </div>
          </Box>

          <UserMgmtNavbar navbarOpen={navbarOpen} />

        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;