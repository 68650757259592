
export const makeStyle = (theme: any) => ({
  container: {
  },
  subContainer: {
    border: 'solid 1px #D1D3D6',
    borderRadius: '8px',
    width: '520px',
    height: 'auto',
    background: '#FFF',
    padding: '32px 44px'
  },
  title: {
    color: '#0D0D0D',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 600
  },
  passwordButton: {
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'none',
    minWidth: '80px',
    borderRadius: '8px',
  },
  passwordButtonText: {
    marginLeft: '5px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFF'
  },
  topBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:  'center',
    marginBottom: '56px'
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataLine: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    padding: '16px 0px',
    borderBottom: 'solid 1px #E8E8E8'
  },
  dataLineWithoutBorder: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    padding: '16px 0px',
  },
  leftPart: {
    width: '50%',
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400
  },
  rightPart: {
    width: '50%',
    color: '#0D0D0D',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700,
  },
  profilText: {
    color: '#0D0D0D',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700,
  },
  versionBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  versionText: {
    color: '#1D2939',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  }
})