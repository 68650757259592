export const translationPT = {
  "explorer": "Explorar",
  "finance": "Finança",
  "financeBoard": "Quadro",
  "financePaymentTransactions": "Transações de pagamento",
  "players": "Joueurs",
  "results": "Résultats",
  "parameters": "Paramètres",

  "globalSearchBarPlaceHolder": "Entrez sua pesquisa aqui",
  "search": "Pesquisar",
  "filters": "Filtros",

  "additional_info": "Informações adicionais",
  "amount max": "Quantia máx",
  "amount maximum": "Quantia máxima",
  "amount min": "Quantia mín",
  "amount minimum": "Quantia mínima",
  "amount": "Montante",
  "commentary": "Comentário",
  "creationDate": "Data de criação",
  "creationDateTime": "Data e hora de criação",
  "currency": "Moeda",
  "date from": "Data de",
  "list": "Lista",
  "no_result": "Sem resultado",
  "operationId": "ID operação",
  "overview": "Visão geral",
  "payment_direction": "Direção de pagamento",
  "payment_direction_select": "Selecione a direção de pagamento",
  "phoneNumber": "Número de telefone",
  "player id": "ID do jogador",
  "player phone number": "Número de telefone do jogador",
  "reset": "Reiniciar",
  "search id": "Encontre uma ID",
  "summary": "Resumo",
  "timeline": "Linha de tempo",
  "to": "No",
  "validate": "Validar",
  "userAccount": "Minha conta",
  "logout": "Desconexão",
  "logoutText1": "Você confirma desconexão ?",
  "logoutText2": "Sim, desconexão",
  "no": "Não",
  "yes": "Sim",
  "Unknown version": "Versão desconhecida",

  "User": {
    "profil": "Prefil",
    "password change": "Alterar palavra-passe",
    "firstname": "Nome",
    "lastname": "Sobrenome",
    "login": "Login",
    "language": "Idioma",
    "profile": "Prefil",
    "group": "Grupo",
    "group timezone": "Fuso horário do grupo"
  },
    
  "Language": {
    "changeLanguageMessage": "Você deseja salvar este idioma como padrão para o usuário?",
    "cancel": "Cancelar"
  },

  "Error": {
    "errorRightMessage1": "Ops, parece que você não tem permissões para esta seção.",
    "errorRightMessage2": "Ops, parece que você deseja visualizar uma página inexistente.",
    "errorRightMessage3": "Erro do servidor, tente novamente mais tarde",
    "goBackToExplorer": "Voltar ao explorador",
    "notFound": "Página não encontrada",
    "request_failed" : "A solicitação falhou.",
    "server_error_retry" : "Erro de servidor. Por favor, tente novamente mais tarde",
  },

  "FormError": {
    "invalid_value" : "Erro, o valor não existe",
    "max_period_days" : "A data não deve exceder {{max}} dias, verifique as datas",
    "min_max_amount": "Erro, verifique por favor a quantia mínima/máxima",    
  },

  "Form": {
    "deselect_all": "desmarcar tudo",
    "select_all": "Selecionar tudo",
    "paymentTransactionSearchPlaceholder": "KP, Plataforma, Provedor"
  },

  "Login": {
    "welcome": "Bem vindo",
    "login": "Login",
    "password": "Senha",
    "login_action": "Entrar",
    "errorMessageLogin1": "O Login ou a senha estão incorretos.",
    "errorMessageLogin2": "Falha na autenticação, contacte o suporte ou inicie sessão com outra conta.",
    "errorMessageLogin3": "Erro do servidor, tente novamente mais tarde.",
  },

  "Platform": {
    "platform": "Plataforma",
    "id": "ID da plataforma",
    "operationId": "ID de operação da plataforma",
    "playerId" : "ID do jogador da plataforma",
    "search": "Pesquisar uma plataforma",
    "select": "Pesquisar uma plataforma",
  },
  "Provider": {
    "provider": "Fornecedor",
    "id": "ID do fornecedor",
    "operationId": "ID da operação do fornecedor",
    "ref": "Fornecedor ref.",
    "search": "Pesquisar um fornecedor",
    "select": "Selecione um fornecedor",
  },
  "Status": {
    "status": "Status",
    "search": "Pesquisar um status",
    "select": "Selecione um status",
  },

  "Transaction": {
    "deposit": "Depósito",
    "regularize": "Regularizar",
    "withdrawal": "Retirada",
    //advanced:
    "back_to_transactions": "Voltar às transações",
    "failure_code": "Código de falha",
    "payment_form_url": "URL do formulário de pagamento",
    "payment_form_url_failure": "URL de falha de pagamento",
    "payment_form_url_success": "URL de sucesso do pagamento",
    "event_failure_code": "Código de falha do evento",
    "event_received_data": "Dados recebidos pelo evento",
    "event_sent_data": "Dados enviados pelo evento",
    "receiver": "Receptor",
    "sender": "Remetente",
    "regulText1": "Tem certeza de que deseja alterar o status? Esta modificação é irreversível.",
    "regul_success": "Regularização realizada com êxito."
  }
}