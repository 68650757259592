import * as React from "react";

const SVGProfilSecond = (props: any) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M8.00004 9C10.2503 9 12.0746 7.20914 12.0746 5C12.0746 2.79086 10.2503 1 8.00004 1C5.74976 1 3.92554 2.79086 3.92554 5C3.92554 7.20914 5.74976 9 8.00004 9Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M15 17C15 13.904 11.8626 11.4 8 11.4C4.13737 11.4 1 13.904 1 17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
}
export default SVGProfilSecond;