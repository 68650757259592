export const makeStyle = (theme: any) => ({
  profilBox: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '-webkit-fill-available',
      paddingLeft: '28px',
      paddingRight: '28px',
      marginBottom: '24px'
  },
  userAccountItem: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
    width: '-webkit-fill-available',
    paddingLeft: '12px',
    height: '40px',
    marginBottom: '5px',
  },
  userAccountItemSelected: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
    width: '-webkit-fill-available',
    paddingLeft: '12px',
    height: '40px',
    marginBottom: '5px',
    backgroundColor: '#E3ECFF',
    borderRadius: '6px'
  },
  nameAccountText: {
      color: '#545452',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 500,
      marginLeft: '12px'
  },
  nameAccountTextCollapse: {
      color: '#545452',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 500,
      opacity: 0
  },
  userAccountText: {
    color: '#545452',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '12px'
  },
  userAccountTextCollapse: {
    color: '#545452',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '12px',
    display: 'none'
  }
})