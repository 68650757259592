import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

const appHistoryContext = React.createContext();
export const AppHistoryProvider = ({ children }) => {

  const [history, setHistory] = React.useState([]);

  const location = useLocation();
  const push = (location) => {
    setHistory([location, ...history].slice(0, 3));
  }

  React.useEffect(() => {
    push(location);
  }, [location]);

  const getLastDistinctLocation = () => {
    return history.find(E => E.pathname != location.pathname);
  }

  return (
    <appHistoryContext.Provider value={{ history, getLastDistinctLocation }}>
      {children}
    </appHistoryContext.Provider>
  );
}

export const useHistoryEx = () => React.useContext(appHistoryContext);

/**
 * useLocation extended with canGoBack method
 */
export const useLocationEx = () => {
  const location = useLocation();

  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    setCanGoBack(!!location.key && location.key != 'default');
  }, [location]);


  return ({ ...location, canGoBack });
}