import React from "react";
import PaymentTransactionsPage from "../finance/paymentTransactions/paymentTransactions.page";
import TransactionDetailsPage from '../finance/transactionDetails/transactionDetails.page';
import SVGFinance from "../../assets/icons/SVGFinance";
import { rights } from "../../common/rights/rights";

export const PATH_FINANCES_MAIN = "/finance/paymentTransactions";

export const isFinancesChildPath = (childPath) => {
  return childPath?.startsWith(PATH_FINANCES_MAIN);
}

export const financesRoutes = [
    {
      component: PaymentTransactionsPage,
      path: PATH_FINANCES_MAIN,
      name: 'financePaymentTransactions',
      right: rights.page_paymentsproviders_operations,
    },
    {
      component: TransactionDetailsPage,
      path: `${PATH_FINANCES_MAIN}/details/:ID`,
      name: 'financeTransactionDetails',
      right: rights.paymentprovider_operation_read,
    }
]

export const financesSideBar = [
  {
      name: 'financePaymentTransactions',
      icon: <SVGFinance />,
      isMultipleRoutes: false,
      path: "/finance/paymentTransactions",
      right: rights.page_paymentsproviders_operations,
  },
]