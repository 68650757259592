import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { computeTransactionBGColor, getTransactionStatusIcon, getTransactionTypeLabel } from '../../../utils/transaction.status.utils';
import { Typography, useTheme, CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import * as transactionActions from '../../../store/actions/transactions.actions';
import { fullDateFormat } from '../../../utils/date.utils';
import { makeStyle } from './paymentTransactions.style';
import PaymentTransactionsSearch from '../../../component/finance/paymentTransactions/paymentTransactionsSearch.comp';
import PaymentTransactionsFilter from '../../../component/finance/paymentTransactions/paymentTransactionsFilter.comp';
import NoResult from '../../../component/common/noResult/noResult.comp';
import { convertSnakeToTitleCase } from '../../../utils/format.utils';
import PaginationComponent from '../../../component/common/pagination/pagination.comp';
import { useToaster } from '../../../component/common/alertToaster/alertToaster.provider';
import SVGChevronDown from '../../../assets/icons/SVGChevronDown';
import { Link } from 'react-router-dom';
import { useHistoryEx } from '../../../utils/navigation.hook';
import { scrollWindowToElement } from '../../../utils/misc.utils';
import { isFinancesChildPath } from '../../routes/finances.routes';
import { RootState } from '../../../store/root.reducer';
import { ITransaction } from '../../../models/finance/transactions.model';

const headCells = [
  "operationId", "Platform.platform", "Provider.provider", "creationDate", "phoneNumber",
  "amount", "payment_direction", "Status.status", "commentary",
];
const defaultSortCell = 'creationDate';

const PaymentTransactionsPage = (): JSX.Element => {

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const toast = useToaster();
  const { history, getLastDistinctLocation } = useHistoryEx();
  const theme = useTheme();
  const style = makeStyle(theme);

  const transactionsData = useSelector((state: RootState) => state.transactions.transactionsData);
  const transactionsState = useSelector((state: RootState) => state.transactions);
  const isTransactionsSearchPending = useSelector((state: RootState) => state.transactions.isTransactionsSearchPending);

  const filters = useSelector((state: RootState) => state.transactions.transactionsFilters);
  const pageSize = useSelector((state: RootState) => state.transactions.transactionsPagination.pageSize);
  const pageNumber = useSelector((state: RootState) => state.transactions.transactionsPagination.pageNumber);
  const scrollPosition = useSelector((state: RootState) => state.transactions.transactionsPagination.scrollPosition);

  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [openFilterBar, setOpenFilterBar] = useState(true);
  const [operationIdSearch, setOperationIdSearch] = useState('');

  const tableContainerRef = useRef<HTMLInputElement>();
  const resultsHeaderRef = useRef<HTMLInputElement>();


  //====================================== Hooks

  useEffect(() => {
    const lastLocation = getLastDistinctLocation();
    if (!isFinancesChildPath(lastLocation?.pathname)) {
      callSearchAPI();
      dispatch(transactionActions.getProviders());
      dispatch(transactionActions.getPlatforms());
    }
  }, [history])

  useEffect(() => {
    //Note: when the data are just re-rendered (same list), keep the scroll position.
    if (scrollPosition != undefined && tableContainerRef.current) {
      tableContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition])

  useEffect(() => {
    transactionsState.isError && toast.showMessage({
      duration: 5000,
      title: t('Error.server_error_retry'),
      severity: 'error',
      showClose: true,
    })
  }, [transactionsState.isError])


  //====================================== Functions

  const callSearchAPI = (_pageNumber = pageNumber, _pageSize = pageSize, withScrolling = false) => {
    if (!isTransactionsSearchPending) {
      const pagination = { page: _pageNumber, size: _pageSize };
      if (openSearchBar) {
        handleValidateIdSearch(operationIdSearch, /*keepPagination=*/true)
      } else {
        dispatch(transactionActions.searchTransactionsByFilter(filters, pagination))
          .then(() => withScrolling && scrollToResults());
      }
    }
  }

  const setFilters = (data: any) => {
    dispatch(transactionActions.set_transactions_filters(data));
  }


  const saveResultsScrollPosition = () => {
    const _scrollPosition = tableContainerRef?.current?.scrollTop;
    dispatch(transactionActions.set_transactions_pagination({ _scrollPosition }));
  }

  const scrollToResults = () => {
    scrollWindowToElement(resultsHeaderRef.current);
  }

  const handleChangePagination = (_pageNumber: number|null, _pageSize: number) => {
    setPagination(_pageNumber, _pageSize);
    callSearchAPI(_pageNumber, _pageSize, /*scrolling=*/true);
  }

  const setPagination = (_pageNumber: number|null, _pageSize: number) => {
    const pagination = { pageNumber: _pageNumber, pageSize: _pageSize, scrollPosition: 0 };
    dispatch(transactionActions.set_transactions_pagination(pagination));
  }

  const handleOpenFilters = () => {
    setOpenSearchBar(!openSearchBar);
    setOpenFilterBar(openSearchBar);
  }

  const handleValidateIdSearch = (ID: string, keepPagination: boolean) => {
    setFilters({});
    if (keepPagination) {
      const pagination_api = { page: pageNumber, size: pageSize };
      dispatch(transactionActions.searchTransactionsById(ID, pagination_api));
    } else {
      setPagination(1, pageSize);
      const pagination_api = { page: 1, size: pageSize };
      dispatch(transactionActions.searchTransactionsById(ID, pagination_api));
    }
  }

  const handleValidateFilters = (_filters: any /* TODO: Setup TypeFiltersTransactionsModel */) => {
    setFilters(_filters);
    setPagination(1, pageSize);
    const pagination = { page: 1, size: pageSize };
    dispatch(transactionActions.searchTransactionsByFilter(_filters, pagination))
      .then(() => scrollToResults());
  }

  const onTransactionLinkClick = () => {
    saveResultsScrollPosition();
  }

  //====================================== Render

  const EnhancedTableHead = () => {

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align={headCell === "amount" ? 'right' : 'left'}
              style={style.tableCellHead}
            >
              <Typography sx={headCell == "amount" ? style.tableLabelRight : style.tableLabel}>
                {t(headCell)}
                {headCell == defaultSortCell && <SVGChevronDown />}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box sx={style.container}>

      {/* FILTERS */}
      <PaymentTransactionsSearch
        openSearchBar={openSearchBar}
        handleOpenFilters={handleOpenFilters}
        onValidate={(ID: string) => handleValidateIdSearch(ID, false)}
        operationIdSearch={operationIdSearch}
        setOperationIdSearch={setOperationIdSearch}
      />
      <PaymentTransactionsFilter
        open={openFilterBar}
        onToggleOpen={handleOpenFilters}
        onValidate={handleValidateFilters}
        defaultFilters={filters}
      />

      <Box sx={style.resultsTopBar} ref={resultsHeaderRef}>
        <Typography sx={style.resultsTitle}>
          {t('list')}
        </Typography>

        {/* PAGINATION */}
        <Box sx={style.paginationContainer}>
          <PaginationComponent
            pageCount={transactionsData?.totalPages}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onChange={(_pageNumber: number|null, _pageSize: number) => handleChangePagination(_pageNumber, _pageSize)}
          />
        </Box>
      </Box>

      {isTransactionsSearchPending &&
        <Box sx={style.searchInProgress}>
          {<CircularProgress size={'30px'} />}
        </Box>
      }

      {/* RESULTS */}
      {!isTransactionsSearchPending &&
        <>
          <TableContainer sx={style.tableContainer} ref={(el: any) => (tableContainerRef.current = el)}>
            <Table
              stickyHeader
              sx={style.table}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead />

              <TableBody>
                {transactionsData?.content?.map((row: ITransaction, index: number) => {
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      tabIndex={-1}
                      sx={{ backgroundColor: computeTransactionBGColor(row.status) }}
                    >
                      <TableCell sx={style.tableCell} align="left">
                        <Link
                          onClick={onTransactionLinkClick}
                          style={style.tableCellLink}
                          to={`/finance/paymentTransactions/details/${row.transactionId}`}>
                          {row.transactionId}
                        </Link>
                      </TableCell>
                      <TableCell sx={style.tableCell} align="left">
                        {row.platform}
                      </TableCell>
                      <TableCell sx={style.tableCell} align="left">
                        {convertSnakeToTitleCase(row.provider)}
                      </TableCell>
                      <TableCell sx={style.tableCell} align="left">
                        {fullDateFormat(row.creationDate)}
                      </TableCell>
                      <TableCell sx={style.tableCell} align="left">
                        {row.userPhoneNumber}
                      </TableCell>
                      <TableCell sx={[style.tableCell]} align="right" >
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                          <div style={{ width: 'max-content' }}>
                            {row.amount.toLocaleString()}
                          </div>
                          <div style={{ marginLeft: '3px', display: 'flex', fontWeight: 400 }}>
                            {row.currency}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell sx={style.tableCell} align="left">
                        {getTransactionTypeLabel(row.transactionType, t)}
                      </TableCell>
                      <TableCell sx={[style.tableCell]} align="left" >
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <div style={{ marginRight: '8px', display: 'flex' }}>
                            {getTransactionStatusIcon(row.status)}
                          </div>
                          {convertSnakeToTitleCase(row.status)}
                        </div>
                      </TableCell>
                      <Tooltip title={row.comment} placement="left">
                        <TableCell sx={{ maxWidth: '150px' }}>
                          <Typography sx={style.tableCell} noWrap>{row.comment}</Typography>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {
              transactionsData?.content?.length == 0 && <NoResult />
            }

          </TableContainer>
        </>
      }
    </Box>
  );
}

export default PaymentTransactionsPage;