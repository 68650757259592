export const makeStyle = (theme: any) => ({
  container: {
    paddingBottom: '22px',
  },
  textField: {
    height: '41px',
    border: '1px solid #D0D5DD',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    
    backgroundColor: '#F8F8F8',
    '& input': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: '#101828',
      "&::placeholder": {
        color: '#7c8496',
        opacity: 1,
      },
    },
    "& fieldset": {
      border: 'none'
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#FFF',
    },
    '& .Mui-error': {
      border: '1px solid red',
      boxShadow: '0px 0px 0px 4px rgba(254, 86, 85, 0.25), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
  },
  textFieldSelected: {
    backgroundColor: '#FFF',
  },
  title: {
    color: '#545452',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '5px',
  },
  errorText: {
    marginTop: '5px',
    color: theme.palette.error.main,
    fontSize: '12px',
  },
})