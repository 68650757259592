import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Popover, useTheme } from '@mui/material';
import SVGGlobe from '../../../assets/icons/SVGGlobe';
import SVGGlobeBlack from '../../../assets/icons/SVGGlobeBlack';
import SVGArrowDownFull from '../../../assets/icons/SVGArrowDownFull';
import SVGArrowDownFullBlack from '../../../assets/icons/SVGArrowDownFullBlack';
import { makeStyle } from './LanguageSelector.style';
import ConfirmPopup from '../confirmPopup/confirmPopup.comp';
import { AVAILABLE_LANGUAGES, transformToISO2 } from '../../../common/i18n/i18n';
import * as UserActions from '../../../store/actions/login.actions';
import { useToaster } from '../alertToaster/alertToaster.provider';
import { RootState } from '../../../store/root.reducer';

type Props = {
  dark: boolean
}

function LanguageSelector({dark}: Props) {

    const { t } = useTranslation();
    const dispatch = useDispatch<any>();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [color, setColor] = useState(dark ? '#000' : '#FFF');
    const [openConfirmPopup, setConfirmPopup] = useState(false);
    const [newLang, setNewLang] = useState<string>();
    const open = Boolean(anchorEl);
    const style = makeStyle(useTheme());
    const toast = useToaster() as any;
    const userData = useSelector((state: RootState) => state.login.userData);

    const openLanguageSelector = (event: any) => {
      setAnchorEl(event.currentTarget);
    }

    const closeLanguageSelector = () => {
      setAnchorEl(null);
    };

    const onLanguageChange = () => {
      const callback = (failed: boolean) => {
        if (failed) {
          toast.showMessage({
            duration: 5000,
            title: t('Error.server_error_retry'),
            severity: 'error',
          })
        }
      }
      setAnchorEl(null);
      dispatch(UserActions.changeUserLanguage(newLang, callback));
      setConfirmPopup(false);
    }

    const onOpenConfirmPopup = (lang: string) => {
      setNewLang(lang);
      setConfirmPopup(true);
      closeLanguageSelector();
    }

    const onClosePopup = () => {
      setConfirmPopup(false)
    }

    return (
        <>
          <ConfirmPopup
            open={openConfirmPopup}
            onClose={onClosePopup}
            title={t("Language.changeLanguageMessage")}
            cancelButtonText={t('Language.cancel')}
            confirmButtonText={t('yes')}
            onValidate={onLanguageChange}
          />
          <Popover 
              open={open}
              anchorEl={anchorEl}
              onClose={closeLanguageSelector}
              disableScrollLock={true}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              sx={dark ? style.popoverDark : style.popover}
          >
                {AVAILABLE_LANGUAGES.map((item, idx) => ( 
                    <Box
                        key={idx}
                        sx={item.lang == userData.language ? style.listItemSelected : style.listItem}
                        onClick={() => onOpenConfirmPopup(item.lang)}
                    >
                      <Typography
                      variant="body1"
                      sx={[item.lang == userData.language && {color: '#FFF'}, style.textLanguage]}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                ))}
          </Popover>
          <Box
              sx={style.selectorBox}
              onClick={openLanguageSelector}
          >
            {dark ? <SVGGlobeBlack /> : <SVGGlobe />}
            <div style={style.selectorSubBox}>
              <Typography sx={[{ color: color }, style.selectorText]}>
                  {transformToISO2(userData.language)}
              </Typography>
              {dark ? <SVGArrowDownFullBlack /> : <SVGArrowDownFull />}
            </div>
          </Box>
        </>
    );
}

export default LanguageSelector;
