import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Typography, useTheme } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { makeStyle } from './dateTimePicker.style';
import dayjs from "dayjs";

/**
 * Date + Time picker
 * 2 methods : use a single datetime value/callback, or split date and time values/callbacks
 * the I/O use the ISO string representation of the datetime
 */

type Props = {
  label: string,
  dateValue: string,
  timeValue: string,
  value?: string,
  onDateChange: any,
  onTimeChange: any,
  onChange: (strDatetime: string, strDate: string, strTime: string) => void,
  implicitTimeValue?: string,
  errorText?: string,
  maxSearchPeriod: number
}

const DateTimePicker = ({
  label,
  dateValue,
  timeValue,
  value,
  onDateChange,
  onTimeChange,
  onChange, //callback(strDatetime, strDate, strTime)
  implicitTimeValue,
  errorText,
  maxSearchPeriod
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  const [dateObj, setDateObj] = useState<any>(null);
  const [timeObj, setTimeObj] = useState<any>(null);

  const [isDatePickerOpen, setDatePickerOpen] = useState<any>(false);
  const [isTimePickerOpen, setTimePickerOpen] = useState<any>(false);


  //====================================== Hooks

  useEffect(() => {
    setDateObj(dateValue ? dayjs(dateValue) : null);
  }, [dateValue]);

  useEffect(() => {
    if (timeValue) {
      const str = dayjs(dateValue).format('YYYY-MM-DDT') + timeValue;
      setTimeObj(dayjs(str));
    }
    else {
      setTimeObj(null);
    }
  }, [timeValue]);

  useEffect(() => {
    if (dateValue || timeValue) {
      return; // ignore full datetime value
    }
    const val = value ? dayjs(value) : null;
    setDateObj(val);
    setTimeObj(val);
  }, [value]);

  //====================================== Functions

  const handleDateChange = (value: any) => {
    setDateObj(value);
    onDateChange && onDateChange(value?.format('YYYY-MM-DD'));
    callOnChange(value, timeObj);
  }
  const handleTimeChange = (value: any) => {
    setTimeObj(value);
    onTimeChange && onTimeChange(value?.format('HH:mm:ss'));
    callOnChange(dateObj, value);
  }

  const callOnChange = (oDate: any, oTime: any) => {
    onChange && onChange(
      buildDateTimeString(oDate, oTime),
      oDate?.format('YYYY-MM-DD'),
      oTime?.format('HH:mm:ss')
    );
  }

  const buildDateTimeString = (oDate: any, oTime: any) => {
    let oDatetime = oDate;
    if (oDate?.isValid()) {
      if (!oTime && implicitTimeValue) {
        oTime = dayjs(dayjs().format('YYYY-MM-DDT') + implicitTimeValue);
      }
      if (oTime?.isValid()) {
        oDatetime = oDate.set('hour', oTime.hour()).set('minute', oTime.minute()).set('second', oTime.second());
      }
    }
    return oDatetime?.isValid() ? oDatetime.toISOString() : null;
  }

  //====================================== Render

  return (
    <Box>
      <Typography sx={style.titleInput}>
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
        <DatePicker
          sx={[
            style.inputBox,
            isDatePickerOpen && style.inputBoxOpen,
            (dateValue || value) && style.inputBoxSelected
          ]}
          slotProps={{
            field: { clearable: true },
          }}
          value={dateObj}
          onChange={handleDateChange}
          onOpen={() => setDatePickerOpen(true)}
          onClose={() => setDatePickerOpen(false)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
        <TimePicker
          sx={[
            style.inputBox,
            isTimePickerOpen && style.inputBoxOpen,
            (timeValue || value) && style.inputBoxSelected
          ]}
          slotProps={{
            field: { clearable: true }
          }}
          views={['hours', 'minutes', 'seconds']}
          value={timeObj}
          onChange={handleTimeChange}
          onOpen={() => setTimePickerOpen(true)}
          onClose={() => setTimePickerOpen(false)}
        />
      </LocalizationProvider>
      {
        errorText &&
        <Typography sx={style.errorText}>
          {t(errorText, { max: maxSearchPeriod })}
        </Typography>
      }
    </Box>
  );
};

export default DateTimePicker;
