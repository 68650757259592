import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import SVGSearch from '../../../assets/icons/SVGSearch';

const SearchBar = (): JSX.Element => {

  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    // TODO: Clear the search input
    console.log("clicked the clear icon...");
  };

  return (
    <div style={{width: '70%', display: 'flex', justifyContent: 'center'}}>
      <FormControl>
        <TextField
          size="small"
          id="main-app-search"
          variant="outlined"
          placeholder="Entrez votre recherche ici"
          onChange={handleChange}
          autoComplete='off'
          sx={{
              width: '650px',
              borderRadius: '40px',
              border: 'solid 2px #D0D5DD',
              backgroundColor: '#FFF',
              '& input': {
                '&::placeholder': {
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: 'rgba(0, 0, 0, 1)',
                },
              },
              "& fieldset": { border: 'none' },
          }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <SVGSearch />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                {/* <ClearIcon /> */}
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </div>
  );
};

export default SearchBar;