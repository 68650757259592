import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { Typography, useTheme } from '@mui/material';
import { makeStyle } from './userMgmtNavbar.style';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import SVGProfil from '../../../assets/icons/SVGProfil';
import SVGArrowDownMenu from '../../../assets/icons/SVGArrowDownMenu'; 
import SVGLogout from '../../../assets/icons/SVGLogout';
import SVGAccount from '../../../assets/icons/SVGAccount';
import * as LoginActions from '../../../store/actions/login.actions';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup.comp';
import { RootState } from '../../../store/root.reducer';

interface Props {
  navbarOpen: boolean;
}

export default function UserMgmtNavbar({navbarOpen}: Props): JSX.Element {

  const styles = makeStyle(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [userAcountCollapse, setUserAccountCollapse] = useState<boolean>(false)
  const [openLogoutPopover, setLogoutPopover] = React.useState<boolean>(false);
  const open = Boolean(openLogoutPopover);
  const userData = useSelector((state: RootState) => state.login.userData);
  
  const onProfileClick = () => {
    setUserAccountCollapse(!userAcountCollapse);
  }

  const onAccountClick = () => {
    navigate('userAccount', {state: {isMyAccount: true}})
  }

  const onOpenLogoutPopover = () => {
    setLogoutPopover(true)
  }

  const onLogout = () => {
    dispatch(LoginActions.userLogout())
  }

  const onLogoutCancel = () => {
    setLogoutPopover(false)
  }

  return (
    <>
      <ConfirmPopup
        open={open}
        onClose={onLogoutCancel}
        onValidate={onLogout}
        title={t('logoutText1')}
        cancelButtonText={t('no')}
        confirmButtonText={t('logoutText2')}
      />

      <Box sx={styles.profilBox} onClick={onProfileClick}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <SVGProfil />
          <p style={navbarOpen ? styles.nameAccountTextCollapse : styles.nameAccountText}>
            {userData.firstName}
          </p>            
        </Box>
        <Box style={navbarOpen ? {display: 'none'} : userAcountCollapse ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}>
          <SVGArrowDownMenu />
        </Box>
      </Box>
      <Collapse in={userAcountCollapse} timeout="auto" sx={[userAcountCollapse && {marginBottom: '32px'}, {width: '-webkit-fill-available', paddingLeft: '16px', paddingRight: '16px'}]}>
        <Box sx={location.state?.isMyAccount ? styles.userAccountItemSelected : styles.userAccountItem} onClick={onAccountClick}>
          <SVGAccount />
          <Typography style={navbarOpen ? styles.userAccountTextCollapse : styles.userAccountText} noWrap>
            {t('userAccount')}
          </Typography>
        </Box>
        <Box sx={styles.userAccountItem} onClick={onOpenLogoutPopover}>
          <SVGLogout />
          <p style={navbarOpen ? styles.userAccountTextCollapse : styles.userAccountText}>
            {t('logout')}
          </p>
        </Box>
      </Collapse>
    </>
  );
}
