/**
 * Push here shared styles and settings
 */
//=========================================================

/**
 * The total height of the application header (topBar incl. padding)
 */
export const APP_HEADER_FULL_HEIGHT = 110;
export const APP_BODY_PADDING = 30;

//=========================================================

/**
 * Contains all the common styles
 */
export const commonStyle = {
  slimScrollBar: {
    '&::-webkit-scrollbar': {
      width: '0.6em',
      height: '0.6em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    }
  },
};

export default commonStyle;