import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { makeStyle } from './genericTextInput.style';
import { useTranslation } from 'react-i18next';

type Props = {
  errorText?: string,
  inputProps?: any,
  label: string,
  placeholder: string,
  leftIcon?: JSX.Element,
  type: string,
  value: string,
  onChange: (value: any, isValid: boolean) => void,
  onApply?: (value: any, isValid: boolean) => void,
  textFieldProps?: any,
  required?: boolean,
  isReset: boolean,
  //Extra: internal self-managed errors
  pattern?: RegExp,
  defaultErrorText?: string,
}

const GenericTextInput = ({
  errorText,
  inputProps,
  label,
  placeholder,
  leftIcon,
  type,
  value,
  onChange,
  onApply,
  textFieldProps,
  required,
  isReset,
  //Extra: internal self-managed errors
  pattern,
  defaultErrorText,
}: Props): JSX.Element => {
  const theme = useTheme();
  const style = makeStyle(theme);
  const { t } = useTranslation();

  const [isValidInterrnal, setIsValidInternal] = useState(true);
  const [errorDisplay, setErrorDisplay] = useState('');

  //====================================== Hooks

  useEffect(() => {
    updateErrorDisplay(isValidInterrnal);
  }, [errorText, isValidInterrnal]);

  // Manage to reset error if reset all filter is asked
  useEffect(() => {
    if (isReset) {
      setErrorDisplay('')
    }
  }, [isReset])

  //====================================== Functions

  const checkInternalValidity = (_value: any) => {
    const isValid = _value?.length > 0 && pattern ? pattern.test(_value) : true;
    setIsValidInternal(isValid);
    updateErrorDisplay(isValid);
    return isValid;
  }

  const updateErrorDisplay = (isValid: boolean) => {
    const _errorDisplay = errorText ? errorText : isValid ? '' : (defaultErrorText || 'FormError.invalid_value');
    setErrorDisplay(_errorDisplay);
  }
    

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _value = event.target.value;
    onChange && onChange(_value, checkInternalValidity(_value));
  }
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _value = event.target.value;
    onApply && onApply(_value, checkInternalValidity(_value));
  }

  //====================================== Render

  return (
    <FormControl fullWidth={true} sx={style.container}>

      <FormLabel sx={style.title}>
        {label}
        {required && <span style={{ color: theme.palette.error.main }}> *</span>}
      </FormLabel>

      <TextField
        size="small"
        variant="outlined"
        autoComplete="off"
        type={type || 'text'}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        sx={[style.textField, value?.length > 0 && style.textFieldSelected]}
        error={errorDisplay?.length > 0}

        InputProps={{
          startAdornment: leftIcon && (
            <InputAdornment position="start">
              {leftIcon}
            </InputAdornment>
          ),
          ...inputProps
        }}
        {...textFieldProps}
      />

      {
        errorDisplay?.length > 0 &&
        <Typography sx={style.errorText}>
          {t(errorDisplay)}
        </Typography>
      }
    </FormControl>
  );
}

export default GenericTextInput;
