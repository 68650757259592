import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import * as transactionActions from '../../../store/actions/transactions.actions';
import { Box, Button, Typography, useTheme, Tabs, Tab } from "@mui/material";
import SVGArrowBackButton from "../../../assets/icons/SVGArrowBackButton";
import SVGRefresh from '../../../assets/icons/SVGRefresh';
import { makeStyle } from './transactionDetails.style';
import PaymentTransactionDetailsSummary from "../../../component/finance/paymentTransactionDetails/paymentTransactionDetailsSummary.comp";
import PaymentTransactionDetailsHistory from "../../../component/finance/paymentTransactionDetails/paymentTransactionDetailsHistory.comp";
import { useToaster } from "../../../component/common/alertToaster/alertToaster.provider";
import { useTranslation } from "react-i18next";
import { useLocationEx } from "../../../utils/navigation.hook";
import { transactionStatusList } from "../../../utils/transaction.status.utils";
import ConfirmPopup from "../../../component/common/confirmPopup/confirmPopup.comp";
import { rights } from "../../../common/rights/rights";
import { RootState } from "../../../store/root.reducer";

function TransactionDetailsPage(): JSX.Element {

  const { ID } = useParams();
  const { t } = useTranslation();
  const location = useLocationEx();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const style = makeStyle(theme);
  const toast = useToaster() as any;
  const [tab, setTab] = React.useState(1);

  const transaction = useSelector((state: RootState) => state.transactions.selectedTransaction);
  const stateDetails = useSelector((state: RootState) => state.transactions.stateDetails);
  const isRegularizationError = useSelector((state: RootState) => state.transactions.isRegularizationError);
  const userRights = useSelector((state: RootState) => state.login.userRights);

  const contentBox = useRef<HTMLInputElement>();

  const [openRegulConfirmPopup, setRegulConfirmPopup] = React.useState(false);
  const open = Boolean(openRegulConfirmPopup);
  const [providerRegulState, setProviderRegulState] = useState(false);
  const [canRegularize, setCanRegularize] = useState(false);

  //====================================== Hooks

  useEffect(() => {
    onRefresh(false);
  }, [ID]);

  useEffect(() => {
    setCanRegularize(
      transaction?.status === transactionStatusList.PROVIDER_REQUESTED
      && userRights.includes(rights.paymentprovider_operation_mcs_regularize)
    )
  }, [transaction])

  useEffect(() => {
    stateDetails.isError && toast.showMessage({
      duration: 5000,
      title: t('Error.server_error_retry'),
      severity: 'error',
      showClose: true,
    })
  }, [stateDetails]);

  useEffect(() => {
    if (isRegularizationError) {
      setRegulConfirmPopup(false);
      toast.showMessage({
          duration: 5000,
          title: t('Error.server_error_retry'),
          severity: 'error',
        })      
    }
  }, [isRegularizationError]);

  useEffect(() => {
    if (contentBox.current) {
      contentBox.current.scrollTop = 0;
    }
  }, [tab])

  //====================================== Functions

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(+newValue);
  };

  const onRefresh = (bSyncList: boolean) => {
    ID && dispatch(transactionActions.searchTransactionDetailsById(ID, bSyncList));
  }

  const goBack = () => {
    location.canGoBack && navigate(-1);
  }

  const onOpenRegulConfirmPopup = (bool: boolean) => {
    setRegulConfirmPopup(true);
    setProviderRegulState(bool);
  }

  const onRegulConfirmPopupCancel = () => {
    setRegulConfirmPopup(false)
  }

  const onRegularizeTransaction = () => {
    const callback = (success: boolean) => {
      if (success) {
        onRefresh(true);
        toast.showMessage({
          duration: 5000,
          title: t('Transaction.regul_success'),
          severity: 'success',
        })
        // onUpdateTransactionsList();
      }

    }
    ID && dispatch(transactionActions.regularizeProviderTransaction(providerRegulState, ID, callback));
    setRegulConfirmPopup(false);
  }

  //====================================== Render

  return (
    <Box sx={style.container}>

      {/* Header */}
      <Box sx={style.headerContainer}>

        {/* Back Button */}
        <Button
          variant="outlined"
          disableRipple
          disableElevation
          sx={style.backButton}
          disabled={!location.canGoBack}
          onClick={goBack}
        >
          <SVGArrowBackButton
            fill={location.canGoBack ? theme.palette.primary.main : '#D0D5DD'}
          />
          <Typography sx={style.backButtonText}>
            {t('Transaction.back_to_transactions')}
          </Typography>
        </Button>

        {/* Tabs */}
        <Box sx={style.headerTabs}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
          >
            <Tab disableRipple value={1}
              label={<Typography sx={[style.tabText, tab == 1 && style.tabTextSelected]}>{t('timeline')}</Typography>}
            />
            <Tab disableRipple value={2}
              label={<Typography sx={[style.tabText, tab == 2 && style.tabTextSelected]}>{t('overview')}</Typography>}
            />
          </Tabs>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center', marginBottom: '10px' }}>
            <Typography sx={style.regulTitle}>{t('Transaction.regularize')}&nbsp;:</Typography>
            <Button
              variant="contained"
              onClick={() => onOpenRegulConfirmPopup(true)}
              sx={style.regulButton}
              disableElevation
              disableRipple
              disabled={!canRegularize}
            >
              {t('Provider.provider')} OK
            </Button>
            <Button
              variant="contained"
              onClick={() => onOpenRegulConfirmPopup(false)}
              sx={style.regulButton}
              disableElevation
              disableRipple
              disabled={!canRegularize}
            >
              {t('Provider.provider')} KO
            </Button>
            <Button
              variant="contained"
              sx={style.refreshButton}
              disableElevation
              disableRipple
              onClick={() => onRefresh(false)}
            >
              <SVGRefresh />
            </Button>
          </Box>

        </Box>

      </Box>

      {/* Content */}
      {
        transaction &&
        <Box sx={style.content} ref={contentBox}>
          {tab == 1 && <PaymentTransactionDetailsHistory transaction={transaction} />}
          {tab == 2 && <PaymentTransactionDetailsSummary transaction={transaction} />}
        </Box>
      }

      <ConfirmPopup
        open={open}
        onClose={onRegulConfirmPopupCancel}
        onValidate={onRegularizeTransaction}
        title={t('Transaction.regulText1')}
        cancelButtonText={t('no')}
        confirmButtonText={t('yes')}
      />

    </Box>
  );
}

export default TransactionDetailsPage;
