export const makeStyle = (theme: any) => ({
  box: {
    width: '100%',
    paddingBottom: "32px",
  },
  filterBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: 'solid 1px #B9B9B9',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '10px',
    paddingTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  collapseBox: {
    width: '-webkit-fill-available',
  },
  collapseBoxOpen: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '10px',
  },
  title: {
    color: '#545452',
    fontWeight: 700,
    fontSize: '20px',
  },
  titleInput: {
    color: '#545452',
    fontWeight: 500,
    fontSize: '16px',
    marginBottom: '5px'
  },
  dropDownBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  filterButtonList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: '0px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: '8px'
  },
  filterButton: {
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '80px',
    borderRadius: '8px',
    '& :hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  resetButton: {
    backgroundColor: 'transparent',
    border: 'solid 2px',
    marginLeft: '16px',
    '&:hover': {
      backgroundColor: '#C4D7F8',
      border: 'solid 2px'
    },
  }
})