import * as React from "react";

const SVGError = ({ width, height, fill }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || 32} height={height || 32} viewBox="0 0 31 32">
      <path
        d="M28.1064 6.16L18.299 0.672C16.6975 -0.224 14.7162 -0.224 13.0981 0.672L3.30728 6.16C1.70573 7.056 0.715088 8.72 0.715088 10.528V21.472C0.715088 23.264 1.70573 24.928 3.30728 25.84L13.1146 31.328C14.7162 32.224 16.6975 32.224 18.3155 31.328L28.1229 25.84C29.7244 24.944 30.7151 23.28 30.7151 21.472V10.528C30.6986 8.72 29.7079 7.072 28.1064 6.16ZM14.4685 9.2C14.4685 8.544 15.0299 8 15.7068 8C16.3838 8 16.9451 8.544 16.9451 9.2V17.6C16.9451 18.256 16.3838 18.8 15.7068 18.8C15.0299 18.8 14.4685 18.256 14.4685 17.6V9.2ZM17.2258 23.408C17.1433 23.6 17.0277 23.776 16.8791 23.936C16.5654 24.24 16.1526 24.4 15.7068 24.4C15.4922 24.4 15.2775 24.352 15.0794 24.272C14.8648 24.192 14.6997 24.08 14.5346 23.936C14.386 23.776 14.2704 23.6 14.1713 23.408C14.0888 23.216 14.0557 23.008 14.0557 22.8C14.0557 22.384 14.2208 21.968 14.5346 21.664C14.6997 21.52 14.8648 21.408 15.0794 21.328C15.6903 21.072 16.4168 21.216 16.8791 21.664C17.0277 21.824 17.1433 21.984 17.2258 22.192C17.3084 22.384 17.3579 22.592 17.3579 22.8C17.3579 23.008 17.3084 23.216 17.2258 23.408Z"
        fill={fill ||'white'}
      />
    </svg>
  )
}
export default SVGError;