export function capitalize(s: string) {
  return s.substr(0, 1).toUpperCase() + s.slice(1).toLowerCase();
}

/**
 * Sort an array of object, by using the sortField property
 */
export const sortByField = (array: any, sortField: string) => {
  return array?.sort((a: any, b: any) => a[sortField] < b[sortField] ? -1 : a[sortField] > b[sortField] ? 1 : 0);
}


/**
 * String Converter to convert snake_case to Title Case
 * Eg.
 * - quick_brown_fox -> Quick Brown Fox
 * - quick_brown____fox -> Quick Brown Fox
 * - quick_brown_fox----jumps_over -> Quick Brown Fox Jumps Over
 *
 */
export const convertSnakeToTitleCase = (text: string) =>
  text
    ?.toLowerCase()
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());

/**
 * Append a new key-value '&param=value' to GET api parameters - only if the value is defined
 */
export const apiConcatParam = (strParameters: string, name: string, value?: string|number) => {
  if (value != undefined)
    return `${strParameters}&${name}=${value}`;
  return strParameters;
}

/**
 * Convert the given string|number to number, return undefined if failed
 */
export const toOptionalNumber = (val: string) => {
  return typeof val == 'number' ? val
    : typeof val == 'string' && val.length && !isNaN(+val) ? +val
      : undefined;
}

/**
 * Tiny XML/HTML prettifier, simple indentation&line management
 * @param {*} tab the indentation string (default \t)
 */
export const prettifyXML = (xml: string, tab: string) => {
  var formatted = '', indent = '';
  tab = tab || '\t';
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    formatted += indent + '<' + node + '>\r\n';
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;              // increase indent
  });
  return formatted.substring(1, formatted.length - 3);
}