import commonStyle, { APP_BODY_PADDING, APP_HEADER_FULL_HEIGHT } from "../../../common/style/commonStyle";

export const makeStyle = (theme: any) => ({
  container: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: '70px',
    },
  },
  headerContainer: {
    position: 'sticky',
    top: `${APP_HEADER_FULL_HEIGHT}px`,
    background: theme.palette.background.main,
  },
  content: {
    ...commonStyle.slimScrollBar,
    //Note:used to have an internal scrollBar instead of a full page scrollBar (headerContainer would be sufficient otherwise)
    maxHeight: `calc(100vh - ${APP_HEADER_FULL_HEIGHT}px - ${APP_BODY_PADDING}px - 136px)`, 
    overflowY: 'auto',
  },
  headerTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px solid #D0D0D0',
  },
  backButton: {
    height: '40px',
    paddingLeft: '12px',
    paddingRight: '16px',
    minWidth: '80px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: 'solid 2px',
    '&:hover': {
      backgroundColor: '#C4D7F8',
      border: 'solid 2px'
    },
    marginBottom: '30px'
  },
  backButtonText: {
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    textTransform: 'none',
  },
  regulButton: {
    height: '40px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '80px',
    borderRadius: '8px',
    marginRight: '16px',
  },
  regulplatformButton: {
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '80px',
    borderRadius: '8px',
    marginRight: '16px'
  },
  refreshButton: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: '8px',
    marginRight: '16px',
    marginLeft: '72px',
    height: '44px',
  },
  tabText: {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    textTransform: 'none',
    color: '#999FA4',
  },
  tabTextSelected: {
    color: '#0D0D0D',
  },
  regulTitle: {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    textTransform: 'none',
    color: '#0D0D0D',
    marginRight: '32px'
  }
})