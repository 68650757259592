import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from "@mui/material";
import { makeStyle } from './paymentTransactionDetailsHistory.style';
import { formatDate } from '../../../utils/date.utils';
import { getTransactionStatusIcon } from '../../../utils/transaction.status.utils';
import { convertSnakeToTitleCase, prettifyXML } from '../../../utils/format.utils';
import SVGArrowRight from '../../../assets/icons/SVGArrowRight';
import { ITransactionDetails, ITransactionEvent } from '../../../models/finance/transactions.model';

const PaymentTransactionDetailsHistory = ({ transaction }: {transaction: ITransactionDetails}): JSX.Element => {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  //====================================== Hooks

  //====================================== Functions  


  const formatEventData = (data: string) => {
    if (!data) {
      return data;
    }
    let formattedData = data;
    if (typeof data == 'object') {
      formattedData = JSON.stringify(data, null, 2);
    }
    else if (typeof data == 'string' && data.startsWith('{')) {
      try {
        const json = JSON.parse(data);
        formattedData = JSON.stringify(json, null, 2);
      } catch (err) {/**/ }
    }
    else if (data?.startsWith('<')) {
      formattedData = prettifyXML(data, '  ');
    }
    return formattedData;
  }


  //====================================== Render

  const StatusValue = ({ status }: { status: string }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', marginRight: '8px' }}>
        {getTransactionStatusIcon(status)}
      </div>
      {convertSnakeToTitleCase(status)}
    </div>
  )

  const EventAttribute = ({ label, value }: {label: string, value: any}) => (
    <Box sx={style.eventAttribute}>
      <Typography sx={style.eventAttributeLabel}>
        {label}&nbsp;:
      </Typography>
      <Box sx={style.eventAttributeValue}>
        {(value != null && (typeof value != 'string' || value.length > 0)) ? value : '...'}
      </Box>
    </Box>
  );

  const EventTransfer = ({ label, data }: {label: string, data: any}) => (
    <Box sx={style.eventTransfer}>
      <Typography sx={style.eventTransferTitle}>
        {label}
      </Typography>
      <Typography sx={style.eventTransferRawData} component='pre'>
        {formatEventData(data) || '...'}
      </Typography>

    </Box>
  );

  const TransactionEventContent = ({ event }: {event: ITransactionEvent}) => (
    <Grid container sx={style.cardContent}>
      <Grid item xs={5} xl={4} sx={style.cardContentLeft}>
        <EventAttribute label={t('operationId')} value={event.details.transactionId} />
        <EventAttribute label={t('Transaction.event_failure_code')} value={event.details.errorCode} />
        <EventAttribute label={t('commentary')} value={event.details.comment} />
      </Grid>
      <Grid item xs={7} xl={8} sx={style.cardContentRight}>
        <Grid container>
          <Grid item xs={6}>
            <EventTransfer label={t('Transaction.event_sent_data')} data={event.details.dataSent} />
          </Grid>
          <Grid item xs={6}>
            <EventTransfer label={t('Transaction.event_received_data')} data={event.details.dataReceived} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const TransactionEventHeader = ({ event }: {event: ITransactionEvent}) => (
    <Box sx={style.cardHeader}>
      <Box sx={style.headerItem}>
        <Typography sx={style.headerItemLabel}>{t('creationDateTime')}</Typography>
        <Typography sx={style.headerItemValue}>{formatDate(event.timestamp, 'DD/MM/YYYY - HH:mm:ss')}</Typography>
      </Box>
      <Box sx={style.headerItem}>
        <Typography sx={style.headerItemLabel}>{t('Status.status')}</Typography>
        <Box sx={style.headerItemValue}><StatusValue status={event.status} /></Box>
      </Box>
      <Box sx={[style.headerItem, style.headerItemFlex]}>
        <Box sx={style.headerItem}>
          <Typography sx={style.headerItemLabel}>{t('Transaction.sender')}</Typography>
          <Typography sx={style.headerItemValue}>{event.sender}</Typography>
        </Box>
        <Box sx={style.headerItem}>
          <Typography sx={style.headerItemLabel}>&nbsp;</Typography>
          <Typography sx={style.headerArrow}><SVGArrowRight /></Typography>
        </Box>
        <Box sx={style.headerItem}>
          <Typography sx={style.headerItemLabel}>{t('Transaction.receiver')}</Typography>
          <Typography sx={style.headerItemValue}>{event.receiver}</Typography>
        </Box>
      </Box>
    </Box>
  )

  const TransactionEvent = ({ event }: {event: ITransactionEvent}): JSX.Element => (
    <Box sx={style.cardContainer}>
      <TransactionEventHeader event={event} />
      <TransactionEventContent event={event} />
    </Box>
  )

  return (
    <Box sx={style.container}>
      {transaction?.events?.map((event: any, index: number) =>
        <TransactionEvent event={event} key={index} />
      )}
    </Box>
  );
}

export default PaymentTransactionDetailsHistory;
