import * as React from "react";

const SVGGlobe = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M25 13C25 15.3734 24.2962 17.6935 22.9776 19.6668C21.6591 21.6402 19.7849 23.1783 17.5922 24.0866C15.3995 24.9948 12.9867 25.2324 10.6589 24.7694C8.33115 24.3064 6.19295 23.1635 4.51472 21.4853C2.83649 19.8071 1.6936 17.6689 1.23058 15.3411C0.767559 13.0133 1.0052 10.6005 1.91345 8.4078C2.8217 6.21509 4.35977 4.34094 6.33316 3.02236C8.30655 1.70379 10.6266 1 13 1C16.1826 1 19.2349 2.26428 21.4853 4.51472C23.7357 6.76516 25 9.8174 25 13Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 13H25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 1C15.4664 4.5176 16.8008 8.70397 16.825 13C16.8008 17.296 15.4664 21.4824 13 25C10.5337 21.4824 9.19929 17.296 9.17505 13C9.19929 8.70397 10.5337 4.5176 13 1Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
export default SVGGlobe;