import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './confirmPopup.style';

type Props = {
  onClose: () => void;
  onValidate: () => void;
  title: string;
  open: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
}

function ConfirmPopup({open, onClose, onValidate, title, cancelButtonText, confirmButtonText}: Props): JSX.Element {

  const styles = makeStyle(useTheme());

  return (
      <Popover 
        open={open}
        onClose={onClose}
        hideBackdrop
        disableScrollLock={true}
        anchorReference={"none"}
        sx={styles.popover}
      >
        <Typography sx={styles.logoutTitle}>{title}</Typography>
        <Box sx={styles.boxButton}>
          <Button
            variant="outlined"
            disableRipple
            disableElevation
            sx={[styles.logoutButton, styles.closeButton]}
            onClick={onClose}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            sx={styles.logoutButton}
            onClick={onValidate}
          >
            {confirmButtonText}
          </Button>            
        </Box>
      </Popover>
  );
}

export default ConfirmPopup;
