import * as React from "react";

const SVGArrowLeftPagination = ({ fill }: any) => {
    return (
      <svg width="15" height="15" viewBox="0 0 20 20" fill={fill || "none"} xmlns="http://www.w3.org/2000/svg">
        <g id="arrow-left">
        <path id="Icon" d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8333M4.16675 10L10.0001 4.16667" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
    )
}
export default SVGArrowLeftPagination;