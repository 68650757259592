
export const makeStyle = (theme: any) => ({
  boxButton: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px'
  },
  logoutButton: {
    flex: 1,
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '80px',
    borderRadius: '8px',
    '& :hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  closeButton: {
    flex: 1,
    backgroundColor: 'transparent',
    border: 'solid 1px',
    minWidth: '108px',
    '&:hover': {
      backgroundColor: '#C4D7F8',
      border: 'solid 1px'
    },
  },
  logoutTitle: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '32px',
    maxWidth: '400px'
  },
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: 'rgba(43 43 43 / 50%)',
    '& .MuiPaper-root': {
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '24px',
      borderRadius: '8px',
      minWidth: '360px',
    }
  }
});