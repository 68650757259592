import { APP_HEADER_FULL_HEIGHT } from "../common/style/commonStyle";

/**
 * Scroll the window to the element, taking care of the current position and the app header
 * @param {*} element the HTML element to scroll into
 * @param {*} headerHeight the window header height, default *APP_HEADER_FULL_HEIGHT*
 */
export const scrollWindowToElement = (element, headerHeight = APP_HEADER_FULL_HEIGHT) => {
  if (!element) {
    return;
  }
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerHeight;
  window.scrollTo({ top: offsetPosition, behavior: "smooth" });
}

export const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(() => resolve(), milliseconds));
}