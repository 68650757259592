export const translationEN = {
  "explorer": "Explorer",
  "finance": "Finance",
  "financeBoard": "Board",
  "financePaymentTransactions": "Payment transactions",
  "players": "Players",
  "results": "Results",
  "parameters": "Parameters",

  "globalSearchBarPlaceHolder": "Enter your search here",
  "search": "Search",
  "filters": "Filters",

  "additional_info": "Additional information",
  "amount max": "Amount max",
  "amount maximum": "Amount maximum",
  "amount min": "Amount min",
  "amount minimum": "Amount minimum",
  "amount": "Amount",
  "commentary": "Comment",
  "creationDate": "Creation date",
  "creationDateTime": "Creation date and time",
  "currency": "Currency",
  "date from": "Date from",
  "list": "List",
  "no_result": "No result",
  "operationId": "Operation ID",
  "overview": "Overview",
  "payment_direction": "Payment direction",
  "payment_direction_select": "Select payment direction",
  "phoneNumber": "Phone number",
  "player id": "Player ID",
  "player phone number": "Player phone number",
  "reset": "Reset",
  "search id": "Search ID",
  "summary": "Summary",
  "timeline": "Timeline",
  "to": "To",
  "validate": "Validate",
  "userAccount": "My account",
  "logout": "Logout",
  "logoutText1": "Do you confirm logout ?",
  "logoutText2": "Yes, logout",
  "no": "No",
  "yes": "Yes",
  "Unknown version": "Unknown version",


  "User": {
    "profil": "Profile",
    "password change": "Change Password",
    "firstname": "Firstname",
    "lastname": "Lastname",
    "login": "Login",
    "language": "Language",
    "profile": "Profile",
    "group": "Group",
    "group timezone": "Group TimeZone"
  },

  "Language": {
    "changeLanguageMessage": "Do you want to save this language as default for user?",
    "cancel": "Cancel"
  },

  "Error": {
    "errorRightMessage1": "Oops, it looks like you don't have permissions for this section.",
    "errorRightMessage2": "Oops, it looks like you want to view a non-existent page.",
    "errorRightMessage3": "Server error, please try again later",
    "goBackToExplorer": "Return to explorer",
    "notFound": "Page not found",
    "request_failed" : "The request has failed.",    
    "server_error_retry" : "Server error. Please try again later",
  },
  
  "FormError": {
    "invalid_value" : "Error, the value does not exist",
    "max_period_days" : "The date should not exceed {{max}} days, please check the dates",
    "min_max_amount": "Error, please check the min/max amount",
  },

  "Form": {
    "deselect_all": "Deselect all",
    "select_all": "Select all",
    "paymentTransactionSearchPlaceholder": "KP, Platform, Provider"
  },

  "Login": {
    "welcome": "Welcome",
    "login": "Login",
    "password": "Password",
    "login_action": "Login",
    "errorMessageLogin1": "Wrong login or password.",
    "errorMessageLogin2": "Authentication failed, please contact support or log in with another account.",
    "errorMessageLogin3": "Server error, please try again later.",
  },

  "Platform": {
    "platform": "Platform",
    "id": "Platform ID",
    "operationId": "Platform operation ID",
    "playerId" : "Platform player ID",
    "search": "Search a platform",
    "select": "Select a platform",
  },
  "Provider": {
    "provider": "Provider",
    "id": "Provider ID",
    "operationId": "Provider operation ID",
    "ref": "Provider ref.",
    "search": "Search a provider",
    "select": "Select a provider",
  },
  "Status": {
    "status": "Status",
    "search": "Search a status",
    "select": "Select a status",
  },

  "Transaction": {
    "deposit": "Deposit",
    "regularize": "Regularize",
    "withdrawal": "Withdrawal",
    //advanced:
    "back_to_transactions": "Back to transactions",
    "failure_code": "Failure code",
    "payment_form_url": "Payment form URL",
    "payment_form_url_failure": "Payment success URL",
    "payment_form_url_success": "Payment failure URL",
    "event_failure_code": "Event failure code",
    "event_received_data": "Data received by the event",
    "event_sent_data": "Data sent by the event",
    "receiver": "Reciever",
    "sender": "Sender",
    "regulText1": "Are you sure you want to change the status? This modification is irreversible.",
    "regul_success": "Regularization carried out successfully."
  }
}