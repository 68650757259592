export const style = {
  buttonStyle: {
    width: '40px',
    height: "40px",
    background: "#FFF",
    fontColor: "#344054",
    fontSize: "12px",
    fontWeight: 500,
    border: 'solid 1px #D0D5DD',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonStyleSelected: {
    width: '40px',
    height: "40px",
    background: "#F9FAFB",
    fontColor: "#344054",
    fontSize: "12px",
    fontWeight: 500,
    border: 'solid 1px #D0D5DD',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  previousButton: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    width: '40px',
    height: "40px",
    background: "#FFF",
    fontColor: "#344054",
    fontSize: "12px",
    fontWeight: 500,
    border: 'solid 1px #D0D5DD',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nextButton: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    width: '40px',
    height: "40px",
    background: "#FFF",
    fontColor: "#344054",
    fontSize: "12px",
    fontWeight: 500,
    border: 'solid 1px #D0D5DD',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectStyle: {
    marginLeft: '15px',
    height: '40px',
    width: '87px',
    borderRadius: '8px',
    backgroundColor: "#FFF",
    fontColor: "#344054",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    border: 'solid 1px #D0D5DD',
    ".MuiOutlinedInput-notchedOutline": { border: 'none' },
    ".MuiOutlinedInput-input.MuiSelect-select": {
      fontSize: "12px",
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: '35px'
    },
  },
  menuItemList: {
    marginTop: '8px',
    borderRadius: '8px',
    boxShadow: 'none',
    border: 'solid 1px #D0D5DD',
    background: '#FFF',
    '& .MuiMenu-list': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .Mui-selected': {
      background: '#F9FAFB',
      "&:hover": { backgroundColor: "transparent" },
    },
  },
  dropDownIcon: {
    display: 'flex',
    pointerEvents: 'none', //required
    position: 'absolute',
    right: '10px'
  },
}