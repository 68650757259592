import * as React from "react";

const SVGProviderUnsuccessful = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 8.74228e-07C4.47715 1.35705e-06 -1.35705e-06 4.47715 -8.74228e-07 10C-3.91405e-07 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 3.91405e-07 10 8.74228e-07ZM12.0549 11.5304L9.57523 13.8169C9.31563 14.0604 8.8949 14.0604 8.63082 13.821C8.46969 13.6724 8.40255 13.4619 8.44731 13.2556L8.87252 11.3653L6.34365 11.3653C5.38133 11.3653 4.73233 10.4573 5.10831 9.64016L6.56744 6.49939C6.71067 6.19811 7.03293 6 7.391 6L11.4193 6C11.9116 6 12.3145 6.37145 12.3145 6.82543L12.3145 10.9485C12.3145 11.1672 12.2205 11.3777 12.0549 11.5304ZM13.2096 6.82543C13.2096 6.37145 13.6125 6 14.1048 6C14.5972 6 15 6.37145 15 6.82543L15 10.1272C15 10.5812 14.5972 10.9526 14.1048 10.9526C13.6125 10.9526 13.2096 10.5812 13.2096 10.1272L13.2096 6.82543Z" fill="#FFC107"/>
        </svg>
    )
}
export default SVGProviderUnsuccessful;
