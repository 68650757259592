import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import {
  FormControl,
  InputAdornment,
  TextField,
  IconButton,
  useTheme
} from "@mui/material";
import { makeStyle } from './paymentTransactionsSearch.style';
import SVGChevronDown from '../../../assets/icons/SVGChevronDown';
import SVGSearch from '../../../assets/icons/SVGSearch';
import SVGCrossClose from '../../../assets/icons/SVGCrossClose';

const idRegexMatch = /[^a-zA-Z0-9\-_]/g;
const inputMaxLength = 37;

type TypeProps = {
  openSearchBar: boolean,
  handleOpenFilters: () => void,
  onValidate: (filters: any) => void,
  operationIdSearch: string,
  setOperationIdSearch: any,
}

function PaymentTransactionsSearch({
  openSearchBar,
  handleOpenFilters,
  onValidate,
  operationIdSearch,
  setOperationIdSearch
}: TypeProps): JSX.Element {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  const resetInput = () => {
    setOperationIdSearch('');
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= inputMaxLength) return;
    setOperationIdSearch(e.target.value.replace(idRegexMatch, ""))
  }

  const handleValidate = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && operationIdSearch.length > 0) {
      onValidate && onValidate(operationIdSearch)
    }
  }

  return (
    <Box sx={style.box}>
      <Typography sx={style.title}>
        {t('search')}
      </Typography>
      <Box sx={style.filterBox}>
        <Box sx={style.dropDownBox} onClick={handleOpenFilters}>
          <div style={openSearchBar ? { transform: 'rotate(180deg)' } : {}}>
            <SVGChevronDown />
          </div>
        </Box>
        <Collapse in={openSearchBar} timeout="auto" sx={style.collapse}>
          <Typography sx={style.titleInput}>
            {t('operationId')}
          </Typography>
          <FormControl component="div" sx={{ width: '50%' }}>
            <TextField
              size="small"
              type='text'
              variant="outlined"
              placeholder={t('Form.paymentTransactionSearchPlaceholder')}
              onChange={handleChange}
              onKeyDown={handleValidate}
              value={operationIdSearch}
              autoComplete='off'
              sx={style.textField}
              inputProps={{
                maxLength: inputMaxLength,
              }}
              InputProps={{
                autoComplete: 'off',
                startAdornment: (
                  <InputAdornment position="start">
                    <SVGSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  operationIdSearch &&
                  <InputAdornment
                    position="end"
                  >
                    <IconButton style={{ padding: '0px' }} onClick={resetInput} disableRipple>
                      <SVGCrossClose />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Collapse>
      </Box>
    </Box>
  );
}

export default PaymentTransactionsSearch;
