import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { Grid, Button, useTheme } from "@mui/material";
import SVGSearch from '../../../assets/icons/SVGSearch';
import { makeStyle } from './paymentTransactionsFilter.style';
import SVGChevronDown from '../../../assets/icons/SVGChevronDown';
import GenericTextInput from '../../common/form/input/genericTextInput.comp';
// import GenericMultipleTextInput from '../../common/form/input/genericMultipleTextInput.comp';
import GenericSelectInput from '../../common/form/select/genericSelectInput.comp';
import DateTimePicker from '../../common/form/dateTimePicker/dateTimePicker.comp';
import * as transactionActions from '../../../store/actions/transactions.actions';
import { useDispatch, useSelector } from 'react-redux';
import { transactionStatusList } from '../../../utils/transaction.status.utils';
import { convertSnakeToTitleCase, toOptionalNumber } from '../../../utils/format.utils';
import dayjs from 'dayjs';
import { DEFAULT_SEARCH_PERIOD_HOURS, MAX_SEARCH_PERIOD } from '../../../store/services/transactions.service';
import { RootState } from '../../../store/root.reducer';

type TypeProps = {
  open: boolean,
  onToggleOpen: () => void,
  onValidate: (filters: any) => void,
  onReset?: () => void,
  defaultFilters: any,
}

const PaymentTransactionsFilter = ({
  open,
  onToggleOpen,
  onValidate, //onValidate(filters)
  onReset,
  defaultFilters,
}: TypeProps) => {

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const style = makeStyle(useTheme());

  //---------- Constants

  const PAYMENT_DIRECTIONS = [
    { id: 'DEPOSIT', name: t('Transaction.deposit') },
    { id: 'WITHDRAWAL', name: t('Transaction.withdrawal') },
  ];

  const F_TRANSACTION_STATUS_LIST = Object.keys(transactionStatusList)
    .sort()
    .map(E => ({ id: E, label: convertSnakeToTitleCase(E) }));

    const SEP = ',';

  //---------- Utils

  const REGEX_AMOUNT = /^[0-9]{1,15}([.,][0-9]{1,3})?$/;
  const REGEX_PHONE_NUMBER = /^[0-9]{6,15}$/;
  const REGEX_PLAYER_ID = /^[0-9]{1,18}([,;][0-9]{1,18})*$/;
  const getDefaultEndDate = () => dayjs().format('YYYY-MM-DD');
  const getDefaultEndTime = () => dayjs().format('HH:mm:ss');
  const getDefaultStartDate = () => dayjs().add(-DEFAULT_SEARCH_PERIOD_HOURS, 'hour').format('YYYY-MM-DD');
  const getDefaultStartTime = () => dayjs().add(-DEFAULT_SEARCH_PERIOD_HOURS, 'hour').format('HH:mm:ss');
  const extractDate = (str: string) => str ? dayjs(str).format('YYYY-MM-DD') : null;
  const extractTime = (str: string) => str ? dayjs(str).format('HH:mm:ss') : null;
  const str2array = (strFilter: string) => strFilter?.split(SEP) || [];

  //---------- States

  const platformList = useSelector((state: RootState) => state.transactions.platformList);
  const providerList = useSelector((state: RootState) => state.transactions.providerList);

  //selected filters (comma-separated for the multi selections)
  const [selectedPaymentDirections, setSelectedPaymentDirections] = useState(str2array(defaultFilters?.types));
  const [selectedEndDate, setSelectedEndDate] = useState(extractDate(defaultFilters?.endDate) || getDefaultEndDate());
  const [selectedEndTime, setSelectedEndTime] = useState(extractTime(defaultFilters?.endDate) || getDefaultEndTime());
  const [selectedMaxAmount, setSelectedMaxAmount] = useState(defaultFilters?.maxAmount);
  const [selectedMinAmount, setSelectedMinAmount] = useState(defaultFilters?.minAmount);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(defaultFilters?.phone);
  const [selectedPlatforms, setSelectedPlatforms] = useState(str2array(defaultFilters?.platforms));
  const [selectedProviders, setSelectedProviders] = useState(str2array(defaultFilters?.providers));
  const [selectedPlayerId, setSelectedPlayerId] = useState(defaultFilters?.players);
  const [selectedStatuses, setSelectedStatuses] = useState(str2array(defaultFilters?.status));
  const [selectedStartDate, setSelectedStartDate] = useState(extractDate(defaultFilters?.startDate) || getDefaultStartDate());
  const [selectedStartTime, setSelectedStartTime] = useState(extractTime(defaultFilters?.startDate) || getDefaultStartTime());

  const [platformListDisplay, setPlatformListDisplay] = useState([]);
  const [providerListDisplay, setProviderListDisplay] = useState([]);

  const [isMinAmountValid, setMinAmountValid] = useState(true);
  const [isMaxAmountValid, setMaxAmountValid] = useState(true);
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const [isPlayerIdValid, setPlayerIdValid] = useState(true);
  const [errorMinMaxAmount, setErrorMinMaxAmount] = useState('');
  const [errorPeriod, setErrorPeriod] = useState('');
  const [isFormValid, setFormValid] = useState(true);

  const [isReset, setIsReset] = useState(false);  

  //====================================== Hooks

  useEffect(() => {
    reset(defaultFilters)
  }, [defaultFilters]);

  useEffect(() => {
    dispatch(transactionActions.getProviders());
    dispatch(transactionActions.getPlatforms());
    return () => { };
  }, []);

  useEffect(() => {
    setPlatformListDisplay(platformList?.map((E: any) => ({
      label: convertSnakeToTitleCase(E.name),
      ...E
    })))
  }, [platformList]);

  useEffect(() => {
    setProviderListDisplay(providerList?.map((E: any) => ({
      label: convertSnakeToTitleCase(E.name),
      ...E
    })))
  }, [providerList]);

  useEffect(() => {
    computeFormValidity();
  }, [
    isMinAmountValid, isMaxAmountValid, isPhoneNumberValid, isPlayerIdValid,
    selectedMinAmount, selectedMaxAmount,
    selectedEndDate, selectedStartDate, selectedEndTime, selectedStartTime,
  ]);

  //====================================== Functions    

  const reset = (filters: any = null) => {
    // Reset values
    setSelectedEndDate(extractDate(filters?.endDate) || getDefaultEndDate());
    setSelectedEndTime(extractTime(filters?.endDate) || getDefaultEndTime());
    setSelectedMaxAmount(filters?.maxAmount);
    setSelectedPaymentDirections(str2array(filters?.types));
    setSelectedMinAmount(filters?.minAmount);
    setSelectedPhoneNumber(filters?.phone);
    setSelectedPlatforms(str2array(filters?.platforms));
    setSelectedPlayerId(filters?.players);
    setSelectedProviders(str2array(filters?.providers));
    setSelectedStartDate(extractDate(filters?.startDate) || getDefaultStartDate());
    setSelectedStartTime(extractTime(filters?.startDate) || getDefaultStartTime());
    setSelectedStatuses(str2array(filters?.status));
    
    // Error Message reset 
    setMinAmountValid(true);
    setMaxAmountValid(true);
    setPhoneNumberValid(true);
    setPlayerIdValid(true);
    setErrorPeriod('');
    setErrorMinMaxAmount('');
    setIsReset(true);
    setFormValid(true);
  }

  const computeFormValidity = () => {

    // self-managed fields
    let valid = isMinAmountValid && isMaxAmountValid && isPhoneNumberValid && isPlayerIdValid;

    // other fields
    const minMaxValid = !selectedMinAmount || !selectedMaxAmount || selectedMinAmount <= selectedMaxAmount;
    setErrorMinMaxAmount(minMaxValid ? '' : 'FormError.min_max_amount');

    const periodValid = computeFilterPeriodValidity();
    setErrorPeriod(periodValid ? '' : 'FormError.max_period_days');

    valid = valid && minMaxValid && periodValid;
    setIsReset(false)
    setFormValid(valid);
  }

  const handleValidate = () => {
    //adapt the required period
    // START = start || end - MAX days || start - MAX days / END = end || start + MAX days || NOW
    const oStartDate =
      selectedStartDate ? dayjs(selectedStartDate + 'T' + (selectedStartTime || '00:00:00'))
        : selectedEndDate ? dayjs(selectedEndDate).add(-MAX_SEARCH_PERIOD, 'day')
          : dayjs().add(-MAX_SEARCH_PERIOD, 'day');
    const oEndDate =
      selectedEndDate ? dayjs(selectedEndDate + 'T' + (selectedEndTime || '23:59:59'))
        : selectedStartDate ? dayjs(selectedStartDate).add(MAX_SEARCH_PERIOD, 'day')
          : dayjs();

    // build request
    const filters = {
      endDate: oEndDate.toISOString(), //required
      maxAmount: selectedMaxAmount,
      minAmount: selectedMinAmount,
      types: selectedPaymentDirections?.join(SEP) || undefined,
      phone: selectedPhoneNumber,
      platforms: selectedPlatforms?.join(SEP) || undefined,
      players: selectedPlayerId,
      providers: selectedProviders?.join(SEP) || undefined,
      startDate: oStartDate.toISOString(), //required
      status: selectedStatuses?.join(SEP) || undefined,
    }
    // callback
    onValidate && onValidate(filters);
  }

  const handleReset = () => {
    reset();
    onReset && onReset();
  }

  const onMaxAmountChange = (value: string, isValid: boolean) => {
    setSelectedMaxAmount(toOptionalNumber(value));
    setMaxAmountValid(isValid);
  }
  const onMinAmountChange = (value: string, isValid: boolean) => {
    setSelectedMinAmount(toOptionalNumber(value));
    setMinAmountValid(isValid);
  }
  const onPhoneNumberChange = (value: string, isValid: boolean) => {
    setSelectedPhoneNumber(value);
    setPhoneNumberValid(isValid);
  }
  const onPlayerIdChange = (value: string, isValid: boolean) => {
    setSelectedPlayerId(value);
    setPlayerIdValid(isValid);
  }

  const onPaymentDirectionChange = (values: string[]) => setSelectedPaymentDirections(values);
  const onPlatformsChange = (values: string[]) => setSelectedPlatforms(values);
  const onProvidersChange = (values: string[]) => setSelectedProviders(values);
  const onStatusesChange = (values: string[]) => setSelectedStatuses(values);

  const onStartDateChange = (sDatetime: string, sDate: string, sTime: string) => {
    setSelectedStartDate(sDate);
    setSelectedStartTime(sTime);
  }
  const onEndDateChange = (sDatetime: string, sDate: string, sTime: string) => {
    setSelectedEndDate(sDate);
    setSelectedEndTime(sTime);
  }

  /**
   * The period startDate-endDate must be between 0 and MAX_SEARCH_PERIOD days
   */
  const computeFilterPeriodValidity = () => {
    if (!selectedStartDate || !selectedEndDate) {
      return true;
    }
    const oStartDate = dayjs(selectedStartDate + (selectedStartTime ? 'T' + selectedStartTime : ''));
    const oEndDate = dayjs(selectedEndDate + 'T' + (selectedEndTime || dayjs().format('HH:mm:ss')));
    return oEndDate.diff(oStartDate) >= 0 && oEndDate.diff(oStartDate, 'day') < MAX_SEARCH_PERIOD;
  }

  //====================================== Render

  return (
    <Box sx={style.box}>
      <Typography sx={style.title}>
        {t('filters')}
      </Typography>
      <Box sx={style.filterBox}>
        <Box sx={style.dropDownBox} onClick={onToggleOpen}>
          <div style={open ? { transform: 'rotate(180deg)' } : {}}>
            <SVGChevronDown />
          </div>
        </Box>
        <Collapse in={open} timeout="auto" sx={[style.collapseBox, open && style.collapseBoxOpen]}>
          <Grid container rowSpacing={1} columnSpacing={4}>

            <Grid item xs={4} xl={3}>
              <GenericSelectInput
                list={platformListDisplay}
                preselectedIdList={selectedPlatforms}
                onApply={onPlatformsChange}
                fieldId={'name'}
                fieldLabel={'label'}
                label={t('Platform.platform')}
                placeholder={t('Platform.select')}
                searchPlaceholder={t('Platform.search')}
                multiple={true}
                showSearch={true}
                showSelectAll={true}
                showSelectionCount={true}
                />
            </Grid>
            <Grid item xs={4} xl={3}>
              <GenericSelectInput
                list={providerListDisplay}
                preselectedIdList={selectedProviders}
                onApply={onProvidersChange}
                fieldId={'name'}
                fieldLabel={'label'}
                label={t('Provider.provider')}
                placeholder={t('Provider.select')}
                searchPlaceholder={t('Provider.search')}
                multiple={true}
                showSearch={true}
                showSelectAll={true}
                showSelectionCount={true}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              {/* <GenericMultipleTextInput
                label={t("player id")}
                placeholder={t('search id')}
                type='text'
                value={selectedPlayerId}
                pattern={REGEX_PLAYER_ID}
                leftIcon={<SVGSearch />}
                onChange={onPlayerIdChange}
                isReset={isReset}
              /> */}
              <GenericTextInput
                label={t("player id")}
                placeholder={t('search id')}
                type='text'
                value={selectedPlayerId}
                pattern={REGEX_PLAYER_ID}
                leftIcon={<SVGSearch />}
                onChange={onPlayerIdChange}
                isReset={isReset}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <GenericTextInput
                label={t('player phone number')}
                placeholder={t('phoneNumber')}
                type='tel'
                value={selectedPhoneNumber}
                pattern={REGEX_PHONE_NUMBER}
                leftIcon={<SVGSearch />}
                onChange={onPhoneNumberChange}
                isReset={isReset}
              />
            </Grid>

            <Grid item xs={4} xl={3}>
              <GenericSelectInput
                list={F_TRANSACTION_STATUS_LIST}
                preselectedIdList={selectedStatuses}
                onApply={onStatusesChange}
                fieldId={'id'}
                fieldLabel={'label'}
                label={t('Status.status')}
                placeholder={t('Status.select')}
                searchPlaceholder={t('Status.search')}
                multiple={true}
                showSearch={true}
                showSelectAll={true}
                showSelectionCount={true}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <GenericSelectInput
                list={PAYMENT_DIRECTIONS}
                preselectedIdList={selectedPaymentDirections}
                onApply={onPaymentDirectionChange}
                fieldId={'id'}
                fieldLabel={'name'}
                label={t('payment_direction')}
                placeholder={t('payment_direction_select')}
                multiple={true}
                showSelectAll={true}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <GenericTextInput
                label={t('amount minimum')}
                placeholder={t('amount min')}
                type='number'
                value={selectedMinAmount}
                pattern={REGEX_AMOUNT}
                inputProps={{ inputProps: { min: 0 } }}
                onChange={onMinAmountChange}
                isReset={isReset}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <GenericTextInput
                label={t('amount maximum')}
                placeholder={t('amount max')}
                type='number'
                value={selectedMaxAmount}
                pattern={REGEX_AMOUNT}
                errorText={errorMinMaxAmount}
                inputProps={{ inputProps: { min: selectedMinAmount || 0 } }}
                onChange={onMaxAmountChange}
                isReset={isReset}
              />
            </Grid>

          </Grid>
          <Grid container rowSpacing={1} columnSpacing={4}>

            <Grid item xs={4} xl={3}>
              <DateTimePicker
                label={t('date from')}
                dateValue={selectedStartDate}
                timeValue={selectedStartTime}
                onChange={onStartDateChange}
                maxSearchPeriod={MAX_SEARCH_PERIOD}
                value={undefined}
                onDateChange={undefined}
                onTimeChange={undefined}
                implicitTimeValue={undefined}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <DateTimePicker
                label={t('to')}
                dateValue={selectedEndDate}
                timeValue={selectedEndTime}
                onChange={onEndDateChange}
                errorText={errorPeriod}
                maxSearchPeriod={MAX_SEARCH_PERIOD}
                value={undefined}
                onDateChange={undefined}
                onTimeChange={undefined}
                implicitTimeValue={undefined}
              />
            </Grid>

            {/* FILTER BUTTONS */}
            <Grid item sx={style.filterButtonList}>
              <Button
                variant="contained"
                disableRipple
                disableElevation
                sx={style.filterButton}
                onClick={handleValidate}
                disabled={!isFormValid}
              >
                {t('validate')}
              </Button>
              <Button
                variant="outlined"
                disableRipple
                disableElevation
                sx={[style.filterButton, style.resetButton]}
                onClick={handleReset}
              >
                {t('reset')}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}

export default PaymentTransactionsFilter;
