import React from "react";

function ExplorerPage(): JSX.Element {
  return (
    <div>
      EXPLORER
    </div>
  );
}

export default ExplorerPage;