import React from "react";
import AppBar from '@mui/material/AppBar';
import LanguageSelector from '../../common/languageSelector/LanguageSelector.comp';
import SearchBar from "../searchbar/searchBar.comp";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { APP_HEADER_FULL_HEIGHT } from "../../../common/style/commonStyle";
import { RootState } from "../../../store/root.reducer";
import { IRouteModel } from "../../../models/core/routes.model";

function TopBar({ currentTab }: { currentTab: IRouteModel }): JSX.Element {

  const location = useLocation();
  const { t } = useTranslation();

  const isConnected = useSelector((state: RootState) => state.login.isLogged);

  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{
        height: APP_HEADER_FULL_HEIGHT,
        paddingBottom: '30px',
        backgroundColor: "#FAFBFD",
        flexDirection: 'row',
        alignItems: 'center',
      }}
      component="nav"
    >
      <p
        style={{
          margin: 0,
          marginLeft: '30px',
          fontWeight: 'bold',
          fontSize: '25px',
          fontFamily: 'Roboto',
          color: '#0D0D0D',
          justifyContent: 'left'
        }}
      >
        {t(location.state?.isMyAccount && 'userAccount' || currentTab?.name || location.state?.name || "Backoffice")}
      </p>
      <SearchBar />
      {
        isConnected && <LanguageSelector dark={true} />
      }

    </AppBar>
  );
}

export default TopBar;
