
export const makeStyle = (theme: any) => ({
    popover: {
        '& .MuiPaper-root': {
            borderRadius: '16px',
            backdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            paddingTop: '10px',
            marginTop: '10px'
        }
    },
    popoverDark: {
        '& .MuiPaper-root': {
            borderRadius: '16px',
            backdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            paddingTop: '10px',
            marginTop: '10px'
        }
    },
    listItemSelected: {
        height: '44px',
        width: '180px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: '#4F88E9',
        marginBottom: '10px',
    },
    listItem: {
        height: '44px',
        width: '180px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        backdropFilter: 'blur(15px)',
        '&:hover': {
          backgroundColor: "#C4D7F8",
        }
    },
    textLanguage: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginLeft: '14px',
    }, 
    selectorBox: {
        height: '20px',
        position: 'fixed',
        top: '30px',
        right: '30px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }, 
    selectorSubBox: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '8px'
    },
    selectorText: {
        marginRight: '5px',
        fontSize: '14px',
        fontStyle: 'Roboto',
        fontWeight: 700,
        textTransform: 'uppercase'
  }
})