export const makeStyle = (theme: any) => ({
  box: {
    width: '100%',
    paddingBottom: "32px"
  },
  filterBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: 'solid 1px #B9B9B9',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '15px',
    flexDirection: 'column',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  title: {
    color: '#545452',
    fontWeight: 700,
    fontSize: '20px',
  },
  titleInput: {
    color: '#545452',
    fontWeight: 500,
    fontSize: '16px',
    marginBottom: '5px'
  },
  dropDownBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  collapse: {
    width: '100%',
    paddingLeft: '72px'
  },
  textField: {
    width: '95%',
    borderRadius: '8px',
    border: 'solid 2px #D0D5DD',
    marginBottom: '22px',
    backgroundColor: '#FFF',
    '& input': {
      width: '95%',
      fontSize: '14px',
      fontWeight: 500,
      color: '#414141'
    },
    "& fieldset": { border: 'none' },
  }
})