import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { makeStyle } from './noResult.style';
import { Typography, useTheme } from '@mui/material';
import IMG_NO_RESULTS from '../../../assets/images/no_results.png';

const NoResult = (): JSX.Element => {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  return (
    <Box sx={style.noResult}>
      <Box sx={style.noResultImgBox}>
        <img src={IMG_NO_RESULTS} />
      </Box>
      <Box sx={style.noResultTextBox}>
        <Typography sx={style.noResultText}>
          {t('no_result')}
        </Typography>
      </Box>
    </Box>
  );
}

export default NoResult;
