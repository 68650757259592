import React, { useEffect, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Typography, useTheme } from "@mui/material";
import { makeStyle } from './paymentTransactionDetailsSummary.style';
import { formatDate } from '../../../utils/date.utils';
import { convertSnakeToTitleCase } from '../../../utils/format.utils';
import { getTransactionStatusIcon, getTransactionTypeLabel } from '../../../utils/transaction.status.utils';
import { ITransactionDetails } from '../../../models/finance/transactions.model';

const PaymentTransactionDetailsSummary = ({ transaction}: {transaction: ITransactionDetails}): JSX.Element => {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());

  const [lastErrorCode, setLastErrorCode] = useState<string|number>();

  //====================================== Hooks

  useEffect(() => {
    setLastErrorCode(transaction?.events?.[0]?.details?.errorCode);
  }, [transaction]);

  //====================================== Functions  

  //====================================== Render

  const CardContentRow = ({ children }: { children: JSX.Element|JSX.Element[]}) => (
    <Box sx={style.cardContentRowContainer}>
      <Box sx={style.cardContentRow}>
        {children}
      </Box>
    </Box>
  );

  const TransactionAttribute = ({ label, value }: {label: string, value: any}) => (
    <Box sx={style.transactionAttribute}>
      <Typography sx={style.transactionAttributeLabel}>
        {label}
      </Typography>
      <Box sx={style.transactionAttributeValue}>
        {(value != null && (typeof value != 'string' || value.length > 0)) ? value : '...'}
      </Box>
    </Box>
  );

  const SmallValue = ({ value }: { value: any }) => (
    <span style={{ fontSize: '12px', textAlign: 'right' }}>
      {value || '...'}
    </span>
  );

  const AmountWithCurrencyValue = ({ amount }: { amount: number }) => (
    <>
      {(amount || 0).toLocaleString()}
      <span style={{ fontWeight: 400, paddingLeft: '8px' }}>
        {transaction.currency}
      </span>
    </>
  )

  const StatusValue = ({ status }: { status: string }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', marginRight: '8px' }}>
        {getTransactionStatusIcon(status)}
      </div>
      {convertSnakeToTitleCase(status)}
    </div>
  )


  return (
    <Box sx={style.container}>

      {/* Summary */}
      <Box sx={style.cardContainer}>
        <Box sx={style.card}>
          <Box sx={style.cardHeader}>
            {t('summary')}
          </Box>
          <Box sx={style.cardContent}>
            <CardContentRow>
              <TransactionAttribute label={t('operationId')} value={transaction.transactionId} />
              <TransactionAttribute label={t('Status.status')} value={<StatusValue status={transaction.status} />} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('Provider.provider')} value={convertSnakeToTitleCase(transaction.provider)} />
              <TransactionAttribute label={t('Provider.operationId')} value={transaction.providerTransactionId} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('Platform.platform')} value={convertSnakeToTitleCase(transaction.platform)} />
              <TransactionAttribute label={t('Platform.operationId')} value={transaction.platformTransactionId} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('payment_direction')} value={getTransactionTypeLabel(transaction.transactionType, t)} />
              <TransactionAttribute label={t('amount')} value={<AmountWithCurrencyValue amount={transaction.amount} />} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('creationDate')} value={formatDate(transaction.creationDate, 'DD/MM/YYYY - HH:mm:ss')} />
              <TransactionAttribute label={' '} value={' '} />
            </CardContentRow>
          </Box>
        </Box>
      </Box>

      {/* Additional info */}
      <Box sx={style.cardContainer}>
        <Box sx={style.card}>
          <Box sx={style.cardHeader}>
            {t('additional_info')}
          </Box>
          <Box sx={style.cardContent}>
            <CardContentRow>
              <TransactionAttribute label={t('Provider.id')} value={transaction.providerId} />
              <TransactionAttribute label={t('Transaction.payment_form_url')} value={<SmallValue value={transaction.urlPaymentForm} />} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('Platform.id')} value={transaction.clientId} />
              <TransactionAttribute label={t('Transaction.payment_form_url_success')} value={<SmallValue value={transaction.urlSuccessForm} />} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('Platform.playerId')} value={transaction.platformPlayerId} />
              <TransactionAttribute label={t('Transaction.payment_form_url_failure')} value={<SmallValue value={transaction.urlFailedForm} />} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('currency')} value={transaction.currency} />
              <TransactionAttribute label={t('Transaction.failure_code')} value={lastErrorCode} />
            </CardContentRow>
            <CardContentRow>
              <TransactionAttribute label={t('Provider.ref')} value={transaction.providerRef} />
              <TransactionAttribute label={' '} value={' '} />
            </CardContentRow>
          </Box>
        </Box>
      </Box>

    </Box>
  );
}

export default PaymentTransactionDetailsSummary;
