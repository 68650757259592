import img1 from '../../../assets/images/loginBackgroundImg/img1.jpg';

export const makeStyle = (theme: any) => ({
  main: {
    height: '100vh',
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url(${img1})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.20)',
    borderRadius: '24px',
    border: 'solid 1px #FFFFFF',
    backdropFilter: 'blur(15px)'
  },
  kpLogo: {
    width: '56px',
    height: '56px',
    marginTop: '15%',
    marginBottom: '16px'
  },
  title1: {
    marginBottom: '19px',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: 700
  },
  title2: {
    marginBottom: '40px',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 700
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputTitle: {
    marginTop: '24px',
    marginBottom: '5px',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 700
  },
  input: {
    height: '48px',
    border: 'solid 1px #FFFFFF',
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 'solid 1px #FFFFFF',
    },
    "& :-webkit-autofill": {
      transitionDelay: '9999s',
    },
    color: '#FFF',
    fontSize: '14px',
    fontFamily: 'Roboto',
  },
  button: {
    boxShadow: 0,
    marginTop: '32px',
    mb: '64px',
    width: '276px',
    height: '44px',
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: '16px',
    textTransform: 'none'
  },
  errorContainer: {
    backgroundColor: '#FE5655',
    width: '510px',
    minHeight: '84px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    marginBottom: '50px',
    maxWidth: '510px'
  },
  errorContainerHidden: {
    visibility: 'hidden',
    backgroundColor: '#FE5655',
    width: '510px',
    minHeight: '84px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    marginBottom: '50px',
    maxWidth: '510px'
  },
  errorSubContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  errorContainerIcon: {
    minWidth: '90px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  errorMessage: {
    textAlign: 'left',
    color: '#FFFFFF',
    marginLeft: '16px',
    fontSize: '14px',
    width: '100%'
  },
  version: {
    textAlign: 'center',
    marginBottom: '16px',
    color: '#FFF'
  }
})