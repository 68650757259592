import * as React from "react";

const SVGPending = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47705 15.5229 0 10 0C4.47705 0 0 4.47705 0 10C0 15.5229 4.47705 20 10 20ZM7 7.58496V6C7 5.44995 7.4502 5 8 5H12C12.5498 5 13 5.44995 13 6V7.59009C13 7.85498 12.895 8.11011 12.7051 8.30005L11 10L12.71 11.7151C12.895 11.8999 13 12.155 13 12.4199V14C13 14.55 12.5498 15 12 15H8C7.4502 15 7 14.55 7 14V12.4199C7 12.155 7.10498 11.8999 7.29004 11.71L9 10L7.29492 8.29492C7.18262 8.18286 7.1001 8.0481 7.05127 7.9021C7.01758 7.80054 7 7.6936 7 7.58496Z" fill="#FFC107"/>
        </svg>
    )
}
export default SVGPending;
