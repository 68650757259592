import React, { useEffect, useState } from 'react';
import { makeStyle } from './alertToaster.style';
import { Alert, Box, IconButton, Snackbar, Typography, useTheme } from '@mui/material';
import SVGClose from '../../../assets/icons/SVGClose';
import SVGError from '../../../assets/icons/SVGError';
import SVGCheckCircle from '../../../assets/icons/SVGCheckCircle';
import SVGInformationCircle from '../../../assets/icons/SVGInformationCircle';
import SVGWarning from '../../../assets/icons/SVGWarning';

type Props = {
  duration?: number,
  message?: string,
  severity?: 'success' | 'info' | 'warning' | 'error',
  title?: string,
  onClose?: () => void,
  showClose: boolean,
  open: boolean,
  action?: any,
  anchorOrigin?: any,
}


const AlertToaster = ({
  //====================================== Props
  duration,    // default no duration
  message,     // text or JSX
  open,        // show or hide the toaster
  severity,    // 'success' | 'info' | 'warning' | 'error' - See Alert MUI
  title,
  //Optional:
  action,
  anchorOrigin,// default { vertical: 'top', horizontal: 'right' } - See Alert MUI
  // icon,        // custom icon (otherwise, auto based on severity)
  onClose,
  showClose,
}: Props): JSX.Element => {

  //====================================== Attributes

  const theme = useTheme<any>();
  const style = makeStyle();

  const ICON_ATTRS = { width: 30, height: 30 };

  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [messageColor, setMessageColor] = useState<string>();
  const [messageIcon, setMessageIcon] = useState<any>(null);

  //====================================== Hooks

  useEffect(() => {
    switch (severity) {
      case 'error':
        setBackgroundColor(theme.palette.error.main);
        setMessageColor('#FFF');
        setMessageIcon(<SVGError {...ICON_ATTRS} fill={'#FFF'} />);
        break;
      case 'success':
        setBackgroundColor(theme.palette.success.main);
        setMessageColor(theme.palette.success.dark);
        setMessageIcon(<SVGCheckCircle {...ICON_ATTRS} stroke={theme.palette.success.dark} />);
        break;
      case 'warning':
        setBackgroundColor(theme.palette.warning.main);
        setMessageColor('#FFF');
        setMessageIcon(<SVGWarning {...ICON_ATTRS} fill={'none'} stroke={'#FF0000'} />);
        break;
      case 'info':
        setBackgroundColor(theme.palette.info.main);
        setMessageColor('#FFF');
        setMessageIcon(<SVGInformationCircle {...ICON_ATTRS} fill={theme.palette.info.dark} />);
        break;
      default:
        setBackgroundColor(theme.palette.info.main);
        setMessageColor('#FFF');
        setMessageIcon(null);
        break;
    }
  }, [severity])

  // useState(() => {
  //   icon && setMessageIcon(icon);
  // }, [icon])

  //====================================== Render

  return (
    <Snackbar
      anchorOrigin={{
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        icon={messageIcon || false}
        sx={[style.alert, {
          backgroundColor,
          color: messageColor
        }]}
      >
        <Box sx={style.body}>
          {title &&
            <Typography sx={style.title}>
              {title}
            </Typography>
          }
          {message}
        </Box>

        {action}

        {showClose &&
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            sx={{ marginLeft: '24px' }}
          >
            <SVGClose fill="#FFF" width={24} height={24} />
          </IconButton>
        }
      </Alert>
    </Snackbar>
  );
}

export default AlertToaster;
