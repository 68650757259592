import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translationFR } from './translation.fr';
import { translationEN } from './translation.en';
import { translationPT } from './translation.pt';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';

dayjs.extend(utc);
dayjs.extend(timezone);

const getI18n = () => {

  const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
  };

  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { translation: translationEN },
        fr: { translation: translationFR },
        pt: { translation: translationPT }
      },
      detection: DETECTION_OPTIONS,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false
      }
    });
  return i18n;
}

export const timeZone = dayjs.tz.guess()

export const transformToISO2 = (lang3: string) => {
  return lang3 == 'por' ? 'pt' : lang3 == 'fra' ? 'fr' : 'en';
}

export const setLanguage = (lang: string) => {
  const lang2 = transformToISO2(lang);
  i18n.changeLanguage(lang2);
  dayjs.locale(lang2);
  console.log("Using TimeZone " + timeZone)
  console.log("Using language/locale " + lang2);
}

export const AVAILABLE_LANGUAGES = [
  { lang: 'eng', label: 'English' },
  { lang: 'fra', label: 'Français' },
  { lang: 'por', label: 'Português' },
];

export default getI18n();