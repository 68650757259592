import * as React from "react";

const SVGProviderSuccessful = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47705 15.5229 0 10 0C4.47705 0 0 4.47705 0 10C0 15.5229 4.47705 20 10 20ZM7.94531 8.46948L10.4248 6.18311C10.6846 5.9397 11.105 5.9397 11.3691 6.17896C11.5303 6.32764 11.5977 6.53809 11.5527 6.74438L11.1274 8.63477H13.6562C14.6187 8.63477 15.2676 9.54272 14.8916 10.3599L13.4326 13.5007C13.2896 13.802 12.9673 14 12.6089 14H8.58057C8.08838 14 7.68555 13.6287 7.68555 13.1746V9.05151C7.68555 8.83276 7.7793 8.62231 7.94531 8.46948ZM6.79053 13.1746C6.79053 13.6287 6.3877 14 5.89502 14C5.40283 14 5 13.6287 5 13.1746V9.8728C5 9.41895 5.40283 9.04736 5.89502 9.04736C6.3877 9.04736 6.79053 9.41895 6.79053 9.8728V13.1746Z" fill="#FFC107"/>
        </svg>
    )
}
export default SVGProviderSuccessful;

