export const makeStyle = (theme: any) => ({
  noResult: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: "300px"
  },
  noResultImgBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '10px',
    paddingTop: '10px',
    '& img': {
      height: '150px',
    },
  },
  noResultTextBox: {
    flex: 1,
  },
  noResultText: {
    color: '#aaa',
    fontSize: '26px',
    fontWeight: '700',
    paddingLeft: '80px',
  },
})