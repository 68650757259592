import SVGSuccessful from '../assets/icons/transactions_svg/SVGSuccessful';
import SVGUnsuccessful from '../assets/icons/transactions_svg/SVGUnsuccessful';
import SVGPending from '../assets/icons/transactions_svg/SVGPending';
import SVGRejected from '../assets/icons/transactions_svg/SVGRejected';
import SVGProviderRequested from '../assets/icons/transactions_svg/SVGProviderRequested';
import SVGProviderSuccessful from '../assets/icons/transactions_svg/SVGProviderSuccessful';
import SVGProviderUnsuccessful from '../assets/icons/transactions_svg/SVGProviderUnsuccessful';
import { convertSnakeToTitleCase } from './format.utils';

export const transactionStatusList = {
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    PROVIDER_REQUESTED: 'PROVIDER_REQUESTED',
    PROVIDER_SUCCESSFUL: 'PROVIDER_SUCCESSFUL',
    PROVIDER_UNSUCCESSFUL: 'PROVIDER_UNSUCCESSFUL',
    SUCCESSFUL: 'SUCCESSFUL',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
}

export const computeTransactionBGColor = (status: string) => {
    switch (status) {
        case transactionStatusList.SUCCESSFUL: return '#D7F1E2'; // green
        case transactionStatusList.REJECTED: return '#FCE3E3'; // red
        case transactionStatusList.UNSUCCESSFUL: return '#E1E3FC'; // bleu
        default: return '#FCF3D7'; // yellow
    }
}

export const getTransactionStatusIcon: any = (status: string) => {
  if (!status) return null;
    switch (status) {
        case transactionStatusList.SUCCESSFUL: return <SVGSuccessful />;
        case transactionStatusList.REJECTED: return <SVGRejected />;
        case transactionStatusList.UNSUCCESSFUL: return <SVGUnsuccessful />;
        case transactionStatusList.PENDING: return <SVGPending />;
        case transactionStatusList.PROVIDER_REQUESTED: return <SVGProviderRequested />;
        case transactionStatusList.PROVIDER_SUCCESSFUL: return <SVGProviderSuccessful />;
        case transactionStatusList.PROVIDER_UNSUCCESSFUL: return <SVGProviderUnsuccessful />;
        default: return <SVGSuccessful />;
    }
}

export const getTransactionTypeLabel = (id: string, t: any) => {
  return convertSnakeToTitleCase(
    id == 'DEPOSIT' ? t('Transaction.deposit') : id == 'WITHDRAWAL' ? t('Transaction.withdrawal') : ''
  );
}