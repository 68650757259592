export const translationFR = {
  "explorer": "Explorateur",
  "finance": "Finances",
  "financeBoard": "Board",
  "financePaymentTransactions": "Opérations de paiements",
  "players": "Joueurs",
  "results": "Résultats",
  "parameters": "Paramètres",
  "selections": "Sélections",

  "globalSearchBarPlaceHolder": "Entrez votre recherche ici",
  "search": "Recherche",
  "filters": "Filtres",

  "additional_info": "Informations additionnelles",
  "amount max": "Montant max",
  "amount maximum": "Montant maximum",
  "amount min": "Montant min",
  "amount minimum": "Montant minimum",
  "amount": "Montant",
  "commentary": "Commentaire",
  "creationDate": "Date de création",
  "creationDateTime": "Date et heure de création",
  "currency": "Devise",
  "date from": "Date à partir de",
  "list": "Liste",
  "no_result": "Aucun résultat",
  "operationId": "ID opération",
  "overview": "Aperçu",
  "payment_direction": "Sens de paiement",
  "payment_direction_select": "Sélectionner un sens de paiement",
  "phoneNumber": "N° de téléphone",
  "player id": "ID joueur",
  "player phone number": "Numéro de téléphone du joueur",
  "reset": "Réinitialiser",
  "search id": "Chercher un ID",
  "summary": "Résumé",
  "timeline": "Chronologie",
  "to": "Au",
  "validate": "Valider",
  "userAccount": "Mon compte",
  "logout": "Déconnexion",
  "logoutText1": "Confirmez-vous la déconnexion ?",
  "logoutText2": "Oui, se déconnecter",
  "no": "Non",
  "yes": "Oui",
  "Unknown version": "Version inconnue",

  "User": {
    "profil": "Profil",
    "password change": "Changer le mot de passe",
    "firstname": "Prénom",
    "lastname": "Nom",
    "login": "Login",
    "language": "Langue",
    "profile": "Profil",
    "group": "Groupe",
    "group timezone": "Fuseau horaire du groupe"
  },
  
  "Language": {
    "changeLanguageMessage": "Voulez-vous enregistrer cette langue par défaut pour l'utilisateur? ",
    "cancel": "Annuler"
  },

  "Error": {
    "errorRightMessage1": "Oups, il semblerait que vous ne disposez pas des droits pour cette section.",
    "errorRightMessage2": "Oups, il semblerait que vous souhaitez consulter une page inexistante.",
    "errorRightMessage3": "Erreur serveur, essayez ultérieurement",
    "goBackToExplorer": "Retour à l'explorateur",
    "notFound": "Page introuvable",
    "request_failed" : "La requête n'a pas abouti.",
    "server_error_retry" : "Erreur serveur. Veuillez réessayer ultérieurement",
  },

  "FormError": {
    "invalid_value" : "Erreur, la valeur n’existe pas",
    "max_period_days" : "La date ne doit pas dépasser {{max}} jours, veuillez vérifier les dates",
    "min_max_amount": "Erreur, veuillez vérifier le montant min/max",
  },

  "Form": {
    "deselect_all": "Tout désélectionner",
    "select_all": "Tout sélectionner",
    "paymentTransactionSearchPlaceholder": "KP, Plateforme, Fournisseur"
  },

  "Login": {
    "welcome": "Bienvenue",
    "login": "Identifiant",
    "password": "Mot de passe",
    "login_action": "Connexion",
    "errorMessageLogin1": "L'identifiant et/ou le mot de passe est incorrect.",
    "errorMessageLogin2": "Echec d’authentification, veuillez contacter le support ou connectez-vous avec un autre compte.",
    "errorMessageLogin3": "Erreur serveur, essayez ultérieurement.",
  },


  "Platform": {
    "platform": "Plateforme",
    "id": "ID plateforme",
    "operationId": "ID opération plateforme",
    "playerId" : "ID Joueur plateforme",
    "search": "Chercher une plateforme",
    "select": "Sélectionner une plateforme",
  },
  "Provider": {
    "provider": "Fournisseur",
    "id": "ID fournisseur",
    "operationId": "ID opération fournisseur",
    "ref": "Réf. fournisseur",
    "search": "Chercher un fournisseur",
    "select": "Sélectionner un fournisseur",
  },
  "Status": {
    "status": "Statut",
    "search": "Chercher un statut",
    "select": "Sélectionner un statut",
  },

  "Transaction": {
    "deposit": "Dépôt",
    "regularize": "Régulariser",
    "withdrawal": "Retrait",
    //advanced:
    "back_to_transactions": "Retour aux opérations",
    "failure_code": "Code d'échec",
    "payment_form_url": "URL du formulaire de paiement",
    "payment_form_url_failure": "URL de succès du paiement",
    "payment_form_url_success": "URL d'échec du paiement",
    "event_failure_code": "Code d'échec de l'événement",
    "event_received_data": "Données reçues par l'événement",
    "event_sent_data": "Données envoyées par l'événement",
    "receiver": "Récepteur",
    "sender": "Expéditeur",
    "regulText1": "Êtes-vous sûr de vouloir changer le statut ? Cette modification est irréversible.",
    "regul_success": "Régularisation effectuée avec succès."
  }
}