import * as React from "react";

const SVGArrowBackButton = ({ fill, width, height }: any) => {
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg" >
      <path
        fill={fill || '#2D71E5'}
        d="M19 11.0001H9L12.29 7.71006C12.3837 7.6171 12.4581 7.5065 12.5089 7.38464C12.5597 7.26278 12.5858 7.13207 12.5858 7.00006C12.5858 6.86805 12.5597 6.73734 12.5089 6.61548C12.4581 6.49362 12.3837 6.38302 12.29 6.29006C12.1026 6.10381 11.8492 5.99927 11.585 5.99927C11.3208 5.99927 11.0674 6.10381 10.88 6.29006L6.59 10.5901C6.21441 10.9634 6.00223 11.4705 6 12.0001C6.00487 12.5262 6.21684 13.0292 6.59 13.4001L10.88 17.7001C10.9732 17.7926 11.0838 17.866 11.2054 17.9158C11.3269 17.9657 11.4571 17.9911 11.5885 17.9906C11.7199 17.9902 11.8499 17.9638 11.9712 17.9131C12.0924 17.8624 12.2024 17.7883 12.295 17.6951C12.3876 17.6018 12.4609 17.4913 12.5107 17.3697C12.5606 17.2481 12.586 17.1179 12.5856 16.9865C12.5851 16.8551 12.5588 16.7251 12.508 16.6039C12.4573 16.4827 12.3832 16.3726 12.29 16.2801L9 13.0001H19C19.2652 13.0001 19.5196 12.8947 19.7071 12.7072C19.8946 12.5196 20 12.2653 20 12.0001C20 11.7348 19.8946 11.4805 19.7071 11.293C19.5196 11.1054 19.2652 11.0001 19 11.0001Z"
      />
    </svg>
  )
}
export default SVGArrowBackButton;