import * as React from "react";

const SVGSuccessful = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 9.99914C20.0002 11.9771 19.4138 13.9106 18.315 15.5553C17.2162 17.1999 15.6544 18.4818 13.827 19.2387C11.9997 19.9957 9.98891 20.1937 8.049 19.8078C6.10908 19.4219 4.32718 18.4694 2.92863 17.0708C1.53009 15.6721 0.577733 13.8901 0.192005 11.9502C-0.193723 10.0102 0.00450518 7.99946 0.761619 6.17217C1.51873 4.34489 2.80072 2.78318 4.44546 1.68453C6.09019 0.585884 8.02379 -0.000338821 10.0017 1.46917e-07C12.6534 0.000454588 15.1964 1.05411 17.0714 2.92923C18.9463 4.80436 19.9998 7.34742 20 9.99914ZM8.84319 15.2939L16.2622 7.87489C16.3221 7.81497 16.3697 7.74384 16.4021 7.66554C16.4345 7.58725 16.4512 7.50334 16.4512 7.41859C16.4512 7.33385 16.4345 7.24993 16.4021 7.17164C16.3697 7.09335 16.3221 7.02221 16.2622 6.96229L15.3522 6.05056C15.2923 5.99063 15.2211 5.94309 15.1428 5.91065C15.0645 5.87822 14.9806 5.86153 14.8959 5.86153C14.8111 5.86153 14.7272 5.87822 14.6489 5.91065C14.5706 5.94309 14.4995 5.99063 14.4396 6.05056L8.38904 12.1011L5.56127 9.27335C5.50135 9.21342 5.43022 9.16588 5.35193 9.13345C5.27363 9.10101 5.18972 9.08432 5.10497 9.08432C5.02023 9.08432 4.93631 9.10101 4.85802 9.13345C4.77973 9.16588 4.70859 9.21342 4.64868 9.27335L3.73951 10.1885C3.67958 10.2484 3.63204 10.3196 3.59961 10.3979C3.56717 10.4762 3.55048 10.5601 3.55048 10.6448C3.55048 10.7296 3.56717 10.8135 3.59961 10.8918C3.63204 10.9701 3.67958 11.0412 3.73951 11.1011L7.93317 15.2948C7.99308 15.3547 8.06422 15.4022 8.14251 15.4347C8.2208 15.4671 8.30472 15.4838 8.38946 15.4838C8.47421 15.4838 8.55812 15.4671 8.63641 15.4347C8.71471 15.4022 8.78584 15.3547 8.84576 15.2948L8.84319 15.2939Z" fill="#09B552"/>
        </svg>
    )
}
export default SVGSuccessful;
