import dayjs from "dayjs";

export function fullDateFormat(value) {
  if (!value) {
      return ""
  }
  return dayjs(value).format('DD/MM/YY - HH:mm:ss');
}

export function formatDate(value, format) {
  return value ? dayjs(value).format(format) : '';
}