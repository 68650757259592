import * as service from '../services/transactions.service';
import { transactionStatusList } from '../../utils/transaction.status.utils';
import { IPagination } from '../../models/finance/transactions.model';
import { TypeDispatchArg, RootState } from '../root.reducer';

export const TRANSACTIONS_ACTIONS = {
  TRANSACTIONS_METADATA_PENDING: 'TRANSACTIONS_METADATA_PENDING',
  TRANSACTIONS_SEARCH_PENDING: 'TRANSACTIONS_SEARCH_PENDING',
  TRANSACTIONS_SEARCH_SUCCESS: 'TRANSACTIONS_SEARCH_SUCCESS',
  TRANSACTIONS_FAILED: 'TRANSACTIONS_FAILED',
  TRANSACTIONS_LIST_FAILED: 'TRANSACTIONS_LIST_FAILED',
  TRANSACTIONS_PLATFORMS: 'TRANSACTIONS_PLATFORMS',
  TRANSACTIONS_PROVIDERS: 'TRANSACTIONS_PROVIDERS',
  TRANSACTIONS_FILTERS: 'TRANSACTIONS_FILTERS',
  TRANSACTIONS_PAGINATION: 'TRANSACTIONS_PAGINATION',
  TRANSACTIONS_DETAILS_SEARCH_PENDING: 'TRANSACTIONS_DETAILS_SEARCH_PENDING',
  TRANSACTIONS_DETAILS_SEARCH_SUCCESS: 'TRANSACTIONS_DETAILS_SEARCH_SUCCESS',
  TRANSACTIONS_DETAILS_SEARCH_FAILED: 'TRANSACTIONS_DETAILS_SEARCH_FAILED',

  TRANSACTIONS_DETAILS_REGUL_SUCCESS: 'TRANSACTIONS_DETAILS_REGUL_SUCCESS',
  TRANSACTIONS_DETAILS_REGUL_FAILED: 'TRANSACTIONS_DETAILS_REGUL_FAILED',

  TRANSACTIONS_LIST_UPDATE: 'TRANSACTIONS_LIST_UPDATE'
}

export const set_transactions_filters = (data: any) => {
  return { type: TRANSACTIONS_ACTIONS.TRANSACTIONS_FILTERS, payload: data };
}
export const set_transactions_pagination = (data: any) => {
  return { type: TRANSACTIONS_ACTIONS.TRANSACTIONS_PAGINATION, payload: data };
}

//

export const searchTransactionsByFilter = (filters: any, pagination: IPagination) => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_PENDING });
      const r = await service.searchTransactionsByFilter(filters, pagination);
      if (r?.data) {
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_SUCCESS, payload: r.data })
      }
    } catch (err: any) {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_LIST_FAILED, payload: err.response  });
    }
  }
}

export const searchTransactionsById = (id: string, pagination: IPagination) => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_PENDING });
      const r = await service.searchTransactionsById(id, pagination);
      if (r?.data) {
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_SEARCH_SUCCESS, payload: r.data })
      }
    } catch (err: any) {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_LIST_FAILED, payload: err.response  });
    }
  }
}

export const searchTransactionDetailsById = (id: string, bSyncList: boolean) => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_PENDING });
      const r = await service.searchTransactionDetailsById(id);
      if (r?.data) {
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_SUCCESS, payload: r.data })
        if (bSyncList) {
          updateTransactionInList(r?.data)
        }
      }
    } catch (err: any) {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_SEARCH_FAILED, payload: err.response  });
    }
  }
}

export const getPlatforms = () => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_METADATA_PENDING });
      const r = await service.getPlatforms();
      if (r?.data) {
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_PLATFORMS, payload: r.data })
      }
    } catch (err: any) {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_FAILED, payload: err.response });
    }
  }
}

export const getProviders = () => {
  return async (dispatch: TypeDispatchArg) => {
    try {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_METADATA_PENDING });
      const r = await service.getProviders();
      if (r?.data) {
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_PROVIDERS, payload: r.data })
      }
    } catch (err: any) {
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_FAILED, payload: err.response });
    }
  }
}

export const regularizeProviderTransaction = (isSuccessful: boolean, ID: string, callback: any) => {
  return async (dispatch : TypeDispatchArg) => {
    try {
      const r = await service.transactionProviderRegularization(isSuccessful, ID);
      if (r?.status === 200) {
        callback(true)
        dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_REGUL_SUCCESS })
      }
    } catch (err: any) {
      callback(false)
      dispatch({ type: TRANSACTIONS_ACTIONS.TRANSACTIONS_DETAILS_REGUL_FAILED });
    }
  }
}

export const updateTransactionInList = (transaction: any) => {
  return async (dispatch: TypeDispatchArg, getState: RootState) => {

    const isFilteredByStatus = getState().transactions?.transactionsFilters?.status?.includes(transactionStatusList.PROVIDER_REQUESTED);
    const transactionsDataList = getState().transactions?.transactionsData?.content;

    let updateList = [];
    if (isFilteredByStatus) {
      updateList = transactionsDataList?.filter((elem: any) => elem.transactionId != transaction.transactionId);
    } else {
      const transactionIndex = transactionsDataList?.findIndex((elem: any) => elem.transactionId == transaction.transactionId);
      transactionsDataList[transactionIndex] = transaction;
      updateList = [...transactionsDataList];
    }

    dispatch({
      type: TRANSACTIONS_ACTIONS.TRANSACTIONS_LIST_UPDATE,
      payload: {
        ...getState().transactions?.transactionsData,
        content: updateList
      }
    })
  }
}