import * as React from "react";

const SVGClose = ({ fill, width, height }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={height || 24} width={width || 24} fill={fill}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
  )
}
export default SVGClose;