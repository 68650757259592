import * as React from "react";

const SVGArrowRightPagination = (props: any) => {
    return (
      <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow-right">
        <path id="Icon" d="M4.16675 10H15.8334M15.8334 10L10.0001 4.16667M15.8334 10L10.0001 15.8333" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>

    )
}
export default SVGArrowRightPagination;