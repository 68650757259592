export const makeStyle = (theme: any) => ({
  container: {
    paddingRight: '8px',
  },
  cardContainer: {
    paddingTop: '24px',
  },
  cardHeader: {
    backgroundColor: '#f8f8f8',
    border: '1px solid #D1D3D6',
    borderRadius: '8px 8px 0 0',
    padding: '16px',
    paddingLeft: '48px',
    display: 'flex',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#FFF',
    borderRadius: '0 0 8px 8px',
    border: '1px solid #D1D3D6',
    borderTop: 'unset',
    padding: '24px',
  },
  cardContentLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentRight: {
    display: 'flex',
    flexDirection: 'row',
    borderLeft: '1px solid #e1e1e1',
  },

  //-----------
  // HEADER
  headerArrow: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  headerItem: {
    flex: 1, 
  },
  headerItemFlex: {
    display: 'flex',
  },
  headerItemLabel: {
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
  },
  headerItemValue: {
    color: '#0D0D0D',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 700,
  },

  //-----------
  // ATTRIBUTES
  eventAttribute: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '18px',
    paddingRight: '48px',
    paddingBottom: '12px',
  },
  eventAttributeLabel: {
    flex: 1,
    color: '#707070',
    fontWeight: '500',
    fontSize: '16px',
  },
  eventAttributeValue: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#0D0D0D',
    fontWeight: '700',
    fontSize: '16px',
    overflowWrap: 'anywhere',
  },
  eventTransfer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    paddingBottom: '24px',
  },
  eventTransferTitle: {
    paddingBottom: '8px',
    fontWeight: '700',
    fontSize: '16px',
  },
  eventTransferRawData: {
    maxWidth: '500px',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontFamily: 'monospace',
    maxHeight: 'min(400px, 50vh)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em',
      backgroundColor: 'rgba(0,0,0,.02)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    }
  },
})