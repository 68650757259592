import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import SVGArrowLeftPagination from '../../../assets/icons/SVGArrowLeftPagination';
import SVGArrowRightPagination from '../../../assets/icons/SVGArrowRightPagination';
import SVGCheck from '../../../assets/icons/SVGCheck';
import SVGChevronDown from '../../../assets/icons/SVGChevronDown';
import { useEffect } from 'react';
import { Select, MenuItem, Box, OutlinedInput } from '@mui/material';
import { style } from './pagination.style';

type Props = {
  pageCount: number,
  pageNumber: number,
  pageSize: number,
  onChange: (pageNumber: number|null, pageSize: number) => void
}

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const pageSizes = [20, 50, 100, 500];

export default function PaginationComponent({
  pageCount,
  pageNumber,
  pageSize,
  onChange,
}: Props) {

  const pagination = usePagination({
    page: pageNumber || 1,
    count: pageCount || 1,
    siblingCount: 0,
    boundaryCount: 1
  });

  useEffect(() => {
    pagination.items.forEach(E => E.selected = E.page == pageNumber);
  }, [pageNumber])

  const handleChangeRowsPerPage = (event: any) => {
    onChange(1, parseInt(event.target.value));
  };
  const handleChangePageNumber = (value: number|null) => {
    onChange(value, pageSize);
  };

  return (
    <>
      <nav>
        {pageCount == 1 ||
          <List>
            {pagination.items.map(({ page, type, onClick, ...item }, index) => {
              let children = null;

              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = (
                  <button {...item} style={style.buttonStyle} disabled>
                    ...
                  </button>
                );
              } else if (type === 'page') {
                children = (
                  <button
                    type="button"
                    style={pageNumber == page ? style.buttonStyleSelected : style.buttonStyle}
                    onClick={() => handleChangePageNumber(page)}
                    {...item}
                  >
                    {page}
                  </button>
                );
              } else {
                children = (
                  <button
                    type="button"
                    {...item}
                    style={type == "previous" ? style.previousButton : style.nextButton}
                    onClick={() => handleChangePageNumber(type == "previous" ? pageNumber - 1 : pageNumber + 1)}
                  >
                    {type == "previous" ? <SVGArrowLeftPagination /> : <SVGArrowRightPagination />}
                  </button>
                );
              }

              return <li key={index}>{children}</li>;
            })}
          </List>
        }
      </nav>
      <Select
        value={pageSize}
        input={<OutlinedInput />}
        renderValue={value => value}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            sx: style.menuItemList
          }
        }}
        sx={style.selectStyle}
        onChange={handleChangeRowsPerPage}
        IconComponent={() => (
          <Box sx={style.dropDownIcon}>
            <SVGChevronDown />
          </Box>
        )}
      >
        {pageSizes.map((size, idx) => (
          <MenuItem
            key={idx}
            value={size}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: 'Roboto',
            }}
          >
            {size}
            {pageSize === size && <SVGCheck />}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}