export const makeStyle = (theme: any) => ({
    drawer: {
        width: 248,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 248,
            boxSizing: 'border-box',
        },
    },
    drawerCollapse: {
        width: 81,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 81,
            boxSizing: 'border-box',
        },
    },
    topBox: {
        display: 'flex',
        marginTop: '36px',
        marginBottom: '24px',
        alignItems: 'center',
        marginLeft: '22px',
        height: '45px'
    },
    bottomBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '36px',
        alignItems: 'center',
        bottom: '0px',
        position: 'fixed',
        width: 248,
    },
    bottomBoxCollapse: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '36px',
      alignItems: 'center',
      bottom: '0px',
      position: 'fixed',
      width: 81,
  },
    collapseBox: {
        width: '-webkit-fill-available',
        paddingLeft: '16px',
        marginBottom: '24px',
        height: '40px'
    },
    listItemText: {
        marginLeft: '12px',
        fontWeight: 500,
        fontSize: '14px',
        color: '#414141',
    },
    listItemTextCollapse: {
        marginLeft: '12px',
        fontWeight: 500,
        fontSize: '14px',
        color: '#414141',
        opacity: 0
    },
    listItemTextSelected: {
        marginLeft: '12px',
        fontWeight: 500,
        fontSize: '14px',
        color: '#000'
    },
    kpLogo: {
        width: '35px',
        height: '35px',
        marginRight: '10px'
    },
    kpLogoTitle: {
        fontWeight: 700,
        fontSize: '14px',
        fontFamily: 'Roboto'
    },
    kpLogoTitleCollapse: {
        fontWeight: 700,
        fontSize: '14px',
        fontFamily: 'Roboto',
        opacity: 0
    },
})