import React, { useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CircularProgress, useTheme } from '@mui/material';
import SVGEye from '../../../assets/icons/SVGEye';
import SVGProfilSecond from '../../../assets/icons/SVGProfilSecond';
import LOGO_OPERATOR from '../../../assets/images/LOGO_OPERATOR.png';
import SVGError from '../../../assets/icons/SVGError';
import { makeStyle } from './login.style';
import { useDispatch } from 'react-redux';
import * as LoginActions from '../../../store/actions/login.actions';
import * as loginService from '../../../store/services/login.service';
import { ENV } from '../../../env';
import { RootState } from '../../../store/root.reducer';
import { IUserLogin } from '../../../models/core/user.model';

export default function LoginPage(): JSX.Element {

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const style = makeStyle(useTheme());

  const loginState = useSelector((state: RootState) => state.login);
  const isRestoringSession = useSelector((state: RootState) => state.login.isRestoringSession)
  const [showPassword, setShowPassword] = useState(false);
  const currentSessionId = localStorage.getItem(loginService.STORAGE_SESSION_ID_KEY);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const userData: IUserLogin = {
      login: formData.get('login') as string,
      password: formData.get("password") as string,
    };
    dispatch(LoginActions.loginUser(userData))
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={style.main}
    >
      {isRestoringSession && currentSessionId ?
        <CircularProgress size={'20px'} sx={{ color: '#FFF', marginLeft: '10px' }} /> :
        <Box
          sx={style.loginBox}
        >
          <img
            src={LOGO_OPERATOR}
            style={style.kpLogo}
          />
          <Typography style={style.title1}>
            Back Office
          </Typography>
          <Typography style={style.title2}>
            {t('Login.welcome')} !
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={style.formBox}>
            <FormControl sx={{ width: '276px' }}>
              <Typography style={style.inputTitle}>{t('Login.login')}</Typography>
              <OutlinedInput
                sx={style.input}
                required
                fullWidth
                name="login"
                endAdornment={
                  <InputAdornment position="end">
                    <SVGProfilSecond />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ width: '276px' }}>
              <Typography style={style.inputTitle}>{t('Login.password')}</Typography>
              <OutlinedInput
                sx={style.input}
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      <SVGEye />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              sx={style.button}
              disableRipple
              disableElevation
            >
              {t('Login.login_action')} {loginState.isLoginPending && <CircularProgress size={'20px'} sx={{ color: '#FFF', marginLeft: '10px' }} />}
            </Button>
          </Box>
          <div style={loginState.isError ? style.errorContainer : style.errorContainerHidden}>
            <div style={style.errorSubContainer} >
              <div style={style.errorContainerIcon}>
                <SVGError />
              </div>
              <Typography sx={style.errorMessage}>
                {t(loginState.errorMessage)}
              </Typography>
            </div>
          </div>
          <Typography sx={style.version}>
            {ENV.APP_VERSION ? `${t('version')} ${ENV.APP_VERSION}` : t('Unknown version')}
          </Typography>
        </Box>
      }
    </Container>
  );
}