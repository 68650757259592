import { ENV } from '../../env';
import dayjs from 'dayjs';
import { apiConcatParam } from '../../utils/format.utils';
import kp_axios from './api.keycloak.service';
import { IPagination } from '../../models/finance/transactions.model';
import { timeZone } from '../../common/i18n/i18n';

export const MAX_SEARCH_PERIOD = 35;//days
export const DEFAULT_SEARCH_PERIOD_HOURS = 1;

export const searchTransactionsByFilter = (filters: any, pagination: IPagination) => {
  console.log(filters.startDate)
  console.log(filters.endDate)
  let apiParams = 'sort=creationDate,desc';
  apiParams = apiConcatParam(apiParams, 'start-date', filters?.startDate || dayjs().add(-DEFAULT_SEARCH_PERIOD_HOURS, 'hour').toISOString()); //required
  apiParams = apiConcatParam(apiParams, 'end-date', filters?.endDate || dayjs().toISOString()); //required
  apiParams = apiConcatParam(apiParams, 'max-amount', filters?.maxAmount);
  apiParams = apiConcatParam(apiParams, 'min-amount', filters?.minAmount);
  apiParams = apiConcatParam(apiParams, 'phone', filters?.phone);
  apiParams = apiConcatParam(apiParams, 'players', filters?.players);
  apiParams = apiConcatParam(apiParams, 'platforms', filters?.platforms);
  apiParams = apiConcatParam(apiParams, 'providers', filters?.providers);
  apiParams = apiConcatParam(apiParams, 'status', filters?.status);
  apiParams = apiConcatParam(apiParams, 'types', filters?.types);
  apiParams = apiConcatParam(apiParams, 'size', pagination?.size);
  apiParams = apiConcatParam(apiParams, 'page', pagination?.page > 0 ? pagination.page - 1 : undefined);
  apiParams = apiConcatParam(apiParams, 'timeZone', timeZone);

  return kp_axios.get(`${ENV.API_URL}/payment-manager/rest/v1/transactions/search-by-filter?${apiParams}`);
}

export const searchTransactionDetailsById = (id: string) => {
  let apiParams = '';
  apiParams = apiConcatParam(apiParams, 'timeZone', timeZone);
  return kp_axios.get(`${ENV.API_URL}/payment-manager/rest/v1/transactions/${id}?${apiParams}`);
}

export const searchTransactionsById = (id: string, pagination: IPagination) => {
  
  let apiParams = 'sort=creationDate,desc';
  apiParams = apiConcatParam(apiParams, 'identifier', id);
  apiParams = apiConcatParam(apiParams, 'size', pagination?.size);
  apiParams = apiConcatParam(apiParams, 'page', pagination?.page > 0 ? pagination.page - 1 : undefined);
  apiParams = apiConcatParam(apiParams, 'timeZone', timeZone);

  return kp_axios.get(`${ENV.API_URL}/payment-manager/rest/v1/transactions/search-by-identifier?${apiParams}`);
}

export const getPlatforms = () => {
  return kp_axios.get(`${ENV.API_URL}/payment-manager/rest/v1/platforms?timeZone=${timeZone}`);
}

export const getProviders = () => {
  return kp_axios.get(`${ENV.API_URL}/payment-manager/rest/v1/providers?timeZone=${timeZone}`);
}

export const transactionProviderRegularization = (isSuccessful: boolean, id: string) => {
  return kp_axios.patch(`${ENV.API_URL}/payment-manager/rest/v1/transactions/${id}/provider-regularization`, {isSuccessful});  
}