import { Box, Typography, useTheme, Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyle } from "./userAccount.style";
import { useTranslation } from "react-i18next";
import SVGPencil from "../../../assets/icons/SVGPencil";
import { fullDateFormat } from "../../../utils/date.utils";
import { ENV } from "../../../env";
import { RootState } from "../../../store/root.reducer";
import { TLanguage } from "../../../models/core/user.model";

function UserAccountPage() {

  const { t } = useTranslation();
  const style = makeStyle(useTheme());
  const userData = useSelector((state: RootState) => state.login.userData);

  const handlePasswordChange = () => {}

  const displayUserLanguage = (lang: string): TLanguage => {
    if (lang == 'fra') return 'Français';
    if (lang == 'eng') return 'English';
    if (lang == 'por') return 'Português';
    else return 'English';
  }

  return ( 
    <Box sx={style.container}>
      <Box sx={style.subContainer}>
        <Box sx={style.topBox}>
          <Typography sx={style.title}>{t('User.profil')}</Typography>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            sx={style.passwordButton}
            onClick={handlePasswordChange}
            disabled={true}
          >
            <SVGPencil />
            <Typography sx={style.passwordButtonText}>{t('User.password change')}</Typography>
          </Button>
        </Box>
        <Box sx={style.dataBox}>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('ID')}</Typography>
            <Typography sx={style.rightPart}>{userData.staffId}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('creationDate')}</Typography>
            <Typography sx={style.rightPart}>{fullDateFormat(userData.creationDate)}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.firstname')}</Typography>
            <Typography sx={style.rightPart}>{userData.firstName}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.lastname')}</Typography>
            <Typography sx={style.rightPart}>{userData.lastName}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.login')}</Typography>
            <Typography sx={style.rightPart}>{userData.login}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.language')}</Typography>
            <Typography sx={style.rightPart}>{displayUserLanguage(userData.language)}</Typography>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.profile')}</Typography>
            <Box sx={style.rightPart}>
              {userData.roles.map((elem: string, idx: number) => <Typography sx={style.profilText} key={idx}>{elem}</Typography>)}
            </Box>
          </Box>
          <Box sx={style.dataLine}>
            <Typography sx={style.leftPart}>{t('User.group')}</Typography>
            <Typography sx={style.rightPart}>{userData.groupName}</Typography>
          </Box>
          <Box sx={style.dataLineWithoutBorder}>
            <Typography sx={style.leftPart}>{t('User.group timezone')}</Typography>
            <Typography sx={style.rightPart}>{userData.timeZone}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={style.versionBox}>
        <Typography sx={style.versionText}>{ENV.APP_VERSION ? `${t('version')} ${ENV.APP_VERSION}` : t('Unknown version')}</Typography>
      </Box>
    </Box>
  );
}

export default UserAccountPage;
