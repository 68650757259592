export const makeStyle = (theme: any) => ({
  box: {
    width: 'fit-content',
  },
  inputBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
    backgroundColor: '#F8F8F8',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      height: '41px',
    },
    '& input': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: '#101828',
      "&::placeholder": {
        color: '#7c8496',
        opacity: 1,
      },
    },
    "& fieldset": {
      border: 'none'
    },
    '&:focus-within': {
      // borderColor: theme.palette.primary.main,
      backgroundColor: '#FFF',
    },
    '& > .Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
      boxShadow: '0px 0px 0px 4px rgba(254, 86, 85, 0.25), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    }
  },
  inputBoxOpen: {
    // borderColor: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
  inputBoxSelected: {
    backgroundColor: '#FFF',
  },
  title: {
    color: '#545452',
    fontWeight: 700,
    fontSize: '24px',
  },
  titleInput: {
    color: '#545452',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '5px',
  },
  dropDownBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
})