import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography, Button } from "@mui/material";
import TennisLogo from "../../../assets/images/errorLogo/tennis_illu.svg";
import BasketLogo from "../../../assets/images/errorLogo/basket_illu.svg";
import FootLogo from "../../../assets/images/errorLogo/foot_illu.svg";
import SVGArrowLeftBack from "../../../assets/icons/SVGArrowLeftBack";
import { style } from "./error.style";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  errorType?: string
}

const ErrorPage = ({ errorType }: Props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const randomizeLogo = () => {
    const logoList = [TennisLogo, FootLogo, BasketLogo];
    const randomIndex = Math.floor(Math.random() * logoList.length);
    return logoList[randomIndex];
  }

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const _errorType = errorType ?? location.state?.errorType;
    switch (_errorType) {
      case 'serverError': setErrorMessage('Error.errorRightMessage3'); break;
      case 'noRight': setErrorMessage('Error.errorRightMessage1'); break;
      default: setErrorMessage('Error.errorRightMessage2'); break;
    }
  }, [errorType, location])

  return (
    <Box sx={style.container}>
      <Box sx={style.leftContainer}>
        <Typography sx={style.firstText}>
          {t("Error.notFound")}
        </Typography>
        <Typography sx={style.secondText}>
          {t(errorMessage)}
        </Typography>
        <Button
          disableRipple
          sx={style.button}
          onClick={() => navigate('/')}
        >
          <SVGArrowLeftBack />
          {t("Error.goBackToExplorer")}
        </Button>
      </Box>
      <Box sx={style.rightContainer}>
        <img style={{ width: '400px' }} src={randomizeLogo()} alt="" />
      </Box>
    </Box>
  );
}

export default ErrorPage;
