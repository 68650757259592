export const makeStyle = () => ({
  alert: {
    alignItems: 'center',
    maxWidth: '800px',
    padding: '10px 24px',
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  body: {
    fontSize: '14px',
    fontWeight: '400',
  },
  title: {
    fontSize: '16px',
    fontWeight: '400',
  }
})