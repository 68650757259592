import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  useTheme,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from "@mui/material";
import { makeStyle } from './genericSelectInput.style';
import SVGCheck from '../../../../assets/icons/SVGCheck';
import SVGChevronDown from '../../../../assets/icons/SVGChevronDown';
import SVGClose from '../../../../assets/icons/SVGClose';
import SVGSearch from '../../../../assets/icons/SVGSearch';

//====================================== 

type Props = {
  list: string[]|any[],
  fieldId: string,
  fieldLabel: any,
  label: string,
  onApply: (values: string[]) => void,
  placeholder: string,
  preselectedIdList: string[],
  //optional:
  fixedWidth?: number,
  multiple?: boolean,
  renderItem?: any,
  searchPlaceholder?: string,
  showSelectAll?: boolean,
  showSelectionCount?: boolean,
  showSearch?: boolean,
}

const GenericSelectInput = ({
  //required:
  list,
  fieldId,
  fieldLabel,
  label,
  onApply, //callback(array of selected items)
  placeholder,
  preselectedIdList,
  //optional:
  fixedWidth,
  multiple,
  renderItem,
  searchPlaceholder,
  showSelectAll,
  showSelectionCount,
  showSearch,
}: Props): JSX.Element => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme, fixedWidth);

  const [selection, setSelection] = useState<any>(multiple ? [] : '');
  const [searchText, setSearchText] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [listDisplay, setListDisplay] = useState(list);

  //====================================== Hooks

  useEffect(() => {
    const _list = preselectedIdList?.length > 0 ? preselectedIdList : [];
    setSelection(multiple ? preselectedIdList ?? [] : _list[0] ?? '');
  }, [preselectedIdList, multiple]);

  useEffect(() => {
    filterListDisplay();
  }, [list, searchText]);


  //====================================== Functions

  const onSelectAll = () => {
    const selectionList = listDisplay.map((E: any) => E[fieldId]);
    setSelection(selectionList);
    onApply && onApply(selectionList);
  }
  const onDeselectAll = () => {
    setSelection([]);
    onApply && onApply([]);
  }

  const onItemPress = (item: any) => {
    if (multiple) {
      let selectionList = [...selection];
      const selectedIndex = selectionList.findIndex(id => id == item[fieldId]);
      if (selectedIndex >= 0) {
        selectionList.splice(selectedIndex, 1);
      } else {
        selectionList = selectionList.concat(item[fieldId]);
      }
      setSelection(selectionList);
      //validate the item selection
      onApply && onApply(selectionList);
    }
    else {
      //(in)validate the item selection
      if (selection == item[fieldId])
        setSelection('')
      else
        setSelection(item[fieldId]);
      onApply && onApply([item[fieldId]]);
    }
  }

  const filterListDisplay = () => {
    if (searchText?.length) {
      const searchTextLC = searchText?.toLowerCase();
      const tmpListDisplay = list?.filter((E: any) => E[fieldLabel]?.toLowerCase().includes(searchTextLC));
      setListDisplay(tmpListDisplay);
    }
    else {
      setListDisplay([...list]);
    }
  }

  //====================================== Render

  const renderItemDefault = (item: any) => {
    const isSelected = multiple ? selection?.findIndex((E: any) => E == item[fieldId]) >= 0 : (selection == item[fieldId]);
    return (
      <MenuItem
        key={item[fieldId]}
        value={item[fieldId]}
        onClick={() => onItemPress(item)}
        selected={isSelected}
        sx={style.menuItem}
      >
        {item[fieldLabel]}
        {isSelected && <Box sx={style.menuItemSelectedIcon}>
          <SVGCheck
            color={theme.palette.primary.main}
            width={20} height={20}
          />
        </Box>}
      </MenuItem>
    )
  };

  const renderInputValue = (value: string) => {
    //no selection
    if (!value || value.length == 0) {
      return <em>{placeholder}</em>;
    }
    //multi selection
    if (Array.isArray(value)) {
      if (showSelectionCount && value.length > 1)
        return `${value.length} ${t('selections').toLowerCase()}`;
      else
        return value.map((id: any) => list.find((item: any) => item[fieldId] == id)?.[fieldLabel]).join(', ');
    }
    // single selection
    return list.find((E: any) => E[fieldId] == value)?.[fieldLabel];
  }

  const renderSearchInput = () => (
    <Box sx={style.searchBox}>
      <FormControl fullWidth={true}>
        <TextField
          sx={style.searchField}
          size="small"
          variant="outlined"
          value={searchText}
          placeholder={searchPlaceholder || t('search')}
          onChange={event => setSearchText(event.target.value)}
          autoComplete='off'
          InputProps={{
            autoComplete: 'off',
            startAdornment:
              <InputAdornment position="start">
                <SVGSearch width={15} height={15} />
              </InputAdornment>
            ,
            endAdornment: searchText &&
              <IconButton onClick={() => setSearchText('')}>
                <SVGClose fill="#222" width={15} height={15} />
              </IconButton>
          }}
        />
      </FormControl>
    </Box>
  );

  const renderSelectAll = () => (
    <Box sx={style.selectAllRow}>
      <Button sx={style.selectAllBtn} onClick={onSelectAll}>
        <Typography sx={style.selectAllBtnText}>
          {t('Form.select_all')}
        </Typography>
      </Button>
      <Button sx={style.selectAllBtn} onClick={onDeselectAll}>
        <Typography sx={style.selectAllBtnText}>
          {t('Form.deselect_all')}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <Box sx={style.box}>
      <Typography sx={style.title}>
        {label}
      </Typography>
      <FormControl fullWidth={true}>
        <Select
          multiple={multiple}
          displayEmpty
          value={selection}
          sx={[style.selectBox, selection?.length > 0 && style.selectBoxSelected, isOpen && style.selectBoxOpen]}
          input={<OutlinedInput />}
          renderValue={renderInputValue}
          IconComponent={() => (
            <Box sx={style.dropDownIcon}>
              <SVGChevronDown />
            </Box>
          )}
          MenuProps={{
            sx: {
              width: fixedWidth,
              marginTop: '8px',
              borderRadius: '8px !important',

              '& .MuiMenu-list': {
                paddingTop: '0px',
                paddingBottom: '0px',
              },
              '& .MuiPaper-root': {
                borderRadius: '8px'
              },
            },
            slotProps: {
              paper: {
                sx: {
                  '& .MuiList-root': { paddingBottom: 0, backgroundColor: '#F9FAFB', },
                }
              }
            }
          }}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onClose={() => setOpen(false)}
        >
          <Box sx={{ padding: '0px' }}>
            {/* Search */}
            {showSearch && renderSearchInput()}

            {/* (de)Select all buttons */}
            {showSelectAll && listDisplay?.length > 0 && renderSelectAll()}

            {/* Item list */}
            <Box sx={style.itemList}>
              {!listDisplay?.length &&
                <Box sx={style.noResult}>
                  {t('no_result')}
                </Box>
              }
              {listDisplay?.map((item) => (
                renderItem && renderItem(item, onItemPress) || renderItemDefault(item)
              ))}
            </Box>
          </Box>
        </Select>
      </FormControl>
    </Box >
  );
}

export default GenericSelectInput;
