export const style = {
  container: {
      display: 'flex',
      paddingLeft: '5%',
      paddingRight: '5%',
      height: '85vh'
  },
  leftContainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'start'
  },
  rightContainer: {
      width: '50%',
      justifyContent: 'center',
      display: 'flex',
  },
  firstText: {
      color: '#F79009',
      fontFamily: 'Roboto',
      fontSize: '55px',
      fontWeight: 700,
  },
  secondText: {
      marginTop: '30px',
      color: '#000',
      fontFamily: 'Roboto',
      fontSize: '24px',
      fontWeight: 400,
  },
  button: {
      marginTop: '50px',
      backgroundColor: '#F79009',
      color: '#FFF',
      fontFamily: 'Roboto',
      borderRadius: '8px',
      textTransform: 'none',
      fontSize: '24px',
      paddingRight: '20px',
      fontWeight: 400,
      "&:hover": {
        backgroundColor: "#F79009"
      },
      "*:first-of-type": {
        marginRight: '8px'
      }
  }
}