export const makeStyle = (theme: any, fixedWidthOpt?: number) => ({
  box: {
    width: fixedWidthOpt,
  },
  title: {
    color: '#545452',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '5px',
  },
  itemList: {
    // padding: '2px',
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  searchBox: {borderRadius: '8px'},
  searchField: {
    backgroundColor: '#FFF',
    "& fieldset": {
      borderRadius: '8px',
    },
    '& .MuiInputBase-root': {
      fontSize: '0.9rem',
    }
  },
  selectBox: {
    cursor: 'pointer',
    height: '45px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    marginBottom: '22px',
    backgroundColor: '#F8F8F8',
    '& em': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: '#667085',
    },
    "& fieldset": {
      border: 'none'
    },
  },
  selectBoxSelected: {
    backgroundColor: '#FFF',
  },
  selectBoxOpen: {
    borderColor: '#4F88E9',
  },
  selectAllRow: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    '& :not(.MuiButton-root:first-of-type)': {
      marginLeft: '12px',
    }
  },
  selectAllBtn: {
    flex: 1,
    color: '#667085',
    height: '44px',
    textTransform: 'capitalize',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    paddingLeft: '14px',
    paddingRight: '14px',
    backgroundColor: '#FFF',
  },
  selectAllBtnText: {
    fontSize: '0.9rem',
    lineHeight: '1rem',
    maxWidth: 100,
    marginLeft: '0px !important',
  },
  dropDownIcon: {
    display: 'flex',
    pointerEvents: 'none', //required
    position: 'absolute',
    right: '10px'
  },
  menuItem: {
    backgroundColor: 'white',
    fontWeight: '500',
    '&.Mui-selected': {
      backgroundColor: '#F9FAFB',
    },
  },
  menuItemSelectedIcon: {
    position: 'absolute',
    right: '14px',
  },
  noResult: {
    color: '#999',
    fontStyle: 'italic',
    padding: '8px',
    paddingLeft: '16px',
  }
})