import commonStyle, { APP_BODY_PADDING, APP_HEADER_FULL_HEIGHT } from "../../../common/style/commonStyle";

export const makeStyle = (theme: any) => ({
  container: {
    width: '100%',
    paddingBottom: "32px"
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FAFBFD'
  },
  searchInProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '80vh', //free space to keep scroll position when using pagination
  },
  tableContainer: {
    ...commonStyle.slimScrollBar,
    borderRadius: '4px',
    maxHeight: `calc(100vh - ${APP_HEADER_FULL_HEIGHT}px - ${APP_BODY_PADDING}px - 40px)`,
  },
  tableLabel: {
    display: 'flex',
    fontFamily: 'Roboto',
    color: '#707070',
    fontWeight: 500,
    fontSize: '14px',
    '& svg': {
      marginLeft: '4px',
    }
  },
  tableLabelRight: {
    display: 'flex',
    fontFamily: 'Roboto',
    color: '#707070',
    fontWeight: 500,
    fontSize: '14px',
    '& svg': {
      marginLeft: '4px',
    },
    justifyContent: 'flex-end'
  },
  resultsTopBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '8px'
  },
  resultsTitle: {
    color: '#545452',
    fontWeight: 700,
    fontSize: '20px'
  },
  table: {
    minWidth: 750,
    backgroundColor: '#F8F8F8',
  },
  tableCell: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#0D0D0D',
  },
  tableCellLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  tableCellHead: {
    borderBottom: "none",
    backgroundColor: '#FFFFFF'
  }
})