import { createTheme } from "@mui/material";
declare module '@mui/material/styles' {
  interface TypeBackground {
    main: string,
  }
}

export const theme = createTheme({
  palette: {
    background: {
      main: '#FAFBFD',
    },
    error: {
      main: '#F04438',
    },
    info: {
      dark: '#002266',
      main: '#AAAAFF',
    },
    primary: {
      main: '#2D71E5',
    },
    secondary: {
      main: '#FDB022',
      dark: '#F79009'
    },
    success: {
      main: '#D1FADF',
      dark: '#054F31'
    },
    warning: {
      main: '#F0AD4E',
    },
  },
});