import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import transactions from './reducers/transactions.reducer';
import login from './reducers/login.reducer';

const rootReducer: any = {
    transactions,
    login
}

const store = createStore(combineReducers(rootReducer), applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;
export type TypeDispatchArg = (arg0: { type: string; payload?: any; }) => void;

export default store;
