export const makeStyle = (theme: any) => ({
  container: {
    paddingRight: '8px',
  },
  cardContainer: {
    paddingTop: '24px',
  },
  card: {
    borderRadius: '8px',
    border: '1px solid #D1D3D6',
    backgroundColor: '#FFF',
  },
  cardHeader: {
    color: '#0D0D0D',
    fontWeight: 600,
    fontSize: '20px',
    padding: '16px',
    paddingLeft: '48px',
  },
  cardContent: {
  },
  cardContentRowContainer: {
    '&:nth-of-type(2n+1)': {
      backgroundColor: '#f8f8f8',
    },
  },
  cardContentRow: {
    maxWidth: '1500px',
    display: 'flex',
    minHeight: '50px',
    justifyContent: 'space-between',
  },
  transactionAttribute: {
    '&:first-of-type': {
      paddingRight: '100px',
    },
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '48px',
    paddingRight: '48px',
    maxWidth: '500px',
  },
  transactionAttributeLabel: {
    flex: 0.5,
    color: '#707070',
    fontWeight: '500',
    fontSize: '16px',
  },
  transactionAttributeValue: {
    flex: 0.5,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#0D0D0D',
    fontWeight: '700',
    fontSize: '16px',
    overflowWrap: 'anywhere',
    textAlign: 'right',
  },
})